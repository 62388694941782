import axios from 'axios';
const http = ({ token } = {}) => {
    const instance = axios.create({
        baseURL: '/api',
        withCredentials: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: token ? `Bearer ${token}` : undefined,
        },
    });
    return instance;
};
const fetcher = (url) => axios
    .get(url, {
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
})
    .then((res) => res.data);
export { http, fetcher };
