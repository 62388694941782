import React from 'react';
import { Layout } from '../../layouts/Layout';
import { useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import { http } from '../../lib/httpClient';
import { useToken } from '../../hooks/useToken';
import { Else, If, Then } from 'react-if';
import { Loading } from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
import { toMoneyString } from '../../lib/format';
const EstimateCard = ({ estimate }) => {
    return (React.createElement("div", { className: "card mb-3" },
        React.createElement("div", { className: "card-body" },
            React.createElement("h5", { className: "card-title fw-bold" },
                estimate.name,
                estimate.name_other ? estimate.name_other : ''),
            React.createElement("h6", { className: "card-subtitle h5 mb-2 text-muted" }, toMoneyString(estimate.total_price)),
            React.createElement("p", { className: "fw-bold text-muted" }, estimate.name_kanji),
            React.createElement("div", { className: "text-end" },
                React.createElement(Link, { to: `/estimates/${estimate.id}/edit`, className: "btn btn-system px-4" }, "\u958B\u304F")))));
};
export const EstimateIndex = () => {
    const { register, handleSubmit } = useForm();
    const [params, setParams] = useSearchParams();
    const { token, refreshToken } = useToken();
    const { data: estimates, isLoading, error, } = useSWR(`/estimates?${params}`, (url) => http({ token: token })
        .get(url)
        .then((res) => res.data)
        .catch((err) => {
        if (err.response.status === 401) {
            return refreshToken().then(() => {
                return http({ token: token }).get(url);
            });
        }
    }));
    const onSubmit = (data) => {
        setParams(data);
    };
    const handlePageChange = (page) => {
        params.set('page', page.toString());
        setParams(params);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u898B\u7A4D\u66F8\u691C\u7D22" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", null,
                            React.createElement(If, { condition: isLoading },
                                React.createElement(Then, null,
                                    React.createElement(Loading, null)),
                                React.createElement(Else, null,
                                    React.createElement(If, { condition: error },
                                        React.createElement(Then, null,
                                            React.createElement("p", { className: "text-danger" }, "\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F")),
                                        React.createElement(Else, null,
                                            React.createElement(If, { condition: estimates?.data.length },
                                                React.createElement(Then, null,
                                                    React.createElement("p", null,
                                                        "\u691C\u7D22\u7D50\u679C: ",
                                                        estimates?.meta.total,
                                                        "\u4EF6")),
                                                React.createElement(Else, null,
                                                    React.createElement("p", null, "\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F")))))))),
                        React.createElement("form", { className: "w-100", method: "get" },
                            React.createElement("div", { className: "input-group mb-3" },
                                React.createElement("input", { type: "search", className: "form-control", placeholder: "\u304A\u5BA2\u69D8\u6C0F\u540D\u307E\u305F\u306F\u96FB\u8A71\u756A\u53F7", defaultValue: params.get('keyword') || '', ...register('keyword') }),
                                React.createElement("button", { type: "submit", className: "btn btn-warning px-4" },
                                    React.createElement("i", { className: "bi bi-search me-2" }),
                                    "\u691C\u7D22"))),
                        React.createElement("div", { className: "w-100" },
                            estimates?.data.map((estimate) => (React.createElement(EstimateCard, { key: estimate.id, estimate: estimate }))),
                            React.createElement(Paginator, { meta: estimates?.meta, onPageChange: handlePageChange }))))))));
};
