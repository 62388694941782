import React, { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { http } from '../../lib/httpClient';
import ConstructionCalendar from '../../template/components/ConstructionCalendar';
import useSWR from 'swr';
import { Layout } from '../../layouts/Layout';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../atoms/AuthAtom';
const STATUSES = [
    { id: 0, name: '未完了' },
    { id: 1, name: '完了' },
    { id: 2, name: '中止（キャンセル）' },
];
const ProjectForm = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const { id } = useParams();
    const [project, setProject] = useState({});
    const [channels, setChannels] = useState([]);
    const { register, handleSubmit, reset, watch } = useForm();
    const auth = useAtomValue(AuthAtom);
    const onSubmit = (data) => {
        if (id) {
            http({ token: auth.token })
                .put(`/projects/${id}`, data)
                .then(() => {
                navigate('/projects');
            })
                .catch((err) => {
                alert(err);
            });
        }
        else {
            http({ token: auth.token })
                .post('/projects', data)
                .then(() => {
                navigate('/projects');
            })
                .catch((err) => {
                alert(err);
            });
        }
    };
    useEffect(() => {
        http({ token: auth.token })
            .get('/channels')
            .then(async (res) => {
            setChannels(res.data.data);
            if (id) {
                http({ token: auth.token })
                    .get(`/projects/${id}`)
                    .then((res) => {
                    setProject(res.data.data);
                    reset(res.data.data);
                });
            }
            else {
                http({ token: auth.token })
                    .get(`/customers/${params.get('customerId')}`)
                    .then((res) => {
                    reset({ channel_id: res.data.data.init_channel_id });
                });
            }
        });
    }, [id]);
    const { data: branches, isLoading: isLoadingBranches } = useSWR(() => `/branches?channel_id=${watch('channel_id') ?? ''}`, (url) => http({ token: auth.token })
        .get(url)
        .then((res) => res.data.data));
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: `案件情報${id ? '更新' : '登録'}` },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "d-flex flex-column" },
                            React.createElement("form", { className: "w-100", onSubmit: handleSubmit(onSubmit) },
                                React.createElement(If, { condition: !project.id },
                                    React.createElement(Then, null,
                                        React.createElement("div", { className: "alert alert-info text-center" },
                                            React.createElement("p", { className: "m-0" },
                                                "\u4EE5\u4E0B\u306E\u9805\u76EE\u3092\u767B\u9332\u3059\u308B\u3068",
                                                React.createElement("b", null, "\u8ABF\u67FB\u5E0C\u671B\u65E5\u6642"),
                                                "\u3068",
                                                React.createElement("b", null, "\u65BD\u5DE5\u671F\u9593"),
                                                "\u304C\u8868\u793A\u3055\u308C\u3001\u8FFD\u8A18\u3067\u304D\u308B\u3088\u3046\u306B\u306A\u308A\u307E\u3059\u3002"))),
                                    React.createElement(Else, null,
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "status", className: "form-label" }, "\u30B9\u30C6\u30FC\u30BF\u30B9"),
                                            React.createElement("select", { id: "status", className: "form-select", required: true, ...register('status') },
                                                React.createElement("option", { disabled: true }, "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
                                                STATUSES.map((status) => (React.createElement("option", { key: status.id, value: status.id }, status.name))))))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-md-4" },
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "channel_id", className: "form-label" }, "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB"),
                                            React.createElement("select", { id: "channel_id", className: "form-select", required: true, ...register('channel_id') },
                                                React.createElement("option", { disabled: true }, "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
                                                channels.map((channel) => (React.createElement("option", { key: channel.id, value: channel.id }, channel.name)))))),
                                    React.createElement("div", { className: "col-md-4" },
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "branch_id", className: "form-label" }, "\u652F\u5E97"),
                                            React.createElement("select", { id: "branch_id", className: "form-select", disabled: isLoadingBranches, required: true, ...register('branch_id') },
                                                React.createElement("option", { disabled: true }, "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
                                                branches?.map((branch) => (React.createElement("option", { key: branch.id, value: branch.id }, branch.name)))))),
                                    React.createElement("div", { className: "col-md-4" },
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "ch_reception_person", className: "form-label" }, "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB\u53D7\u4ED8\u62C5\u5F53\u8005"),
                                            React.createElement("input", { id: "ch_reception_person", type: "text", className: "form-control", placeholder: "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB\u62C5\u5F53\u8005\u540D\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", required: true, ...register('ch_reception_person') })))),
                                React.createElement("div", { className: "row" },
                                    React.createElement("div", { className: "col-md-4" },
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "in_reception_person", className: "form-label" }, "\u81EA\u793E\u53D7\u4ED8\u62C5\u5F53\u8005"),
                                            React.createElement("input", { id: "in_reception_person", type: "text", className: "form-control", placeholder: "\u81EA\u793E\u62C5\u5F53\u8005\u540D\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", required: true, ...register('in_reception_person') })))),
                                React.createElement("div", { className: "mb-3" },
                                    React.createElement("label", { htmlFor: "name", className: "form-label" }, "\u6848\u4EF6\u540D"),
                                    React.createElement("input", { id: "name", type: "text", className: "form-control", placeholder: "\u6848\u4EF6\u540D\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", required: true, ...register('name') })),
                                React.createElement("div", { className: "mb-3" },
                                    React.createElement("label", { htmlFor: "reception_date", className: "form-label" }, "\u53D7\u4ED8\u65E5"),
                                    React.createElement("input", { id: "reception_date", type: "date", className: "form-control", placeholder: "\u53D7\u4ED8\u65E5\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", required: true, ...register('reception_date') })),
                                React.createElement("div", { className: "mb-3" },
                                    React.createElement("label", { htmlFor: "is_parking_available", className: "form-label" }, "\u8A2A\u554F\u6642\u99D0\u8ECA\u5834"),
                                    React.createElement("div", { className: "d-flex flex-row gap-3" },
                                        React.createElement("div", { className: "form-check" },
                                            React.createElement("input", { className: "form-check-input", type: "radio", id: "is_parking_available_yes", value: 1, ...register('is_parking_available') }),
                                            React.createElement("label", { className: "form-check-label", htmlFor: "is_parking_available_yes" }, "\u3042\u308A")),
                                        React.createElement("div", { className: "form-check" },
                                            React.createElement("input", { className: "form-check-input", type: "radio", id: "is_parking_available_no", value: 0, ...register('is_parking_available') }),
                                            React.createElement("label", { className: "form-check-label", htmlFor: "is_parking_available_no" }, "\u306A\u3057")))),
                                React.createElement("div", { className: "mb-3" },
                                    React.createElement("label", { htmlFor: "description", className: "form-label" }, "\u76F8\u8AC7\u5185\u5BB9"),
                                    React.createElement("textarea", { id: "description", className: "form-control", rows: 5, placeholder: "\u76F8\u8AC7\u5185\u5BB9\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", ...register('description') })),
                                React.createElement(If, { condition: project.id },
                                    React.createElement(Then, null,
                                        React.createElement("div", { className: "alert alert-info text-center" },
                                            React.createElement("p", { className: "m-0" },
                                                "\u4EE5\u4E0B\u306F",
                                                React.createElement("b", null, "\u55B6\u696D\u62C5\u5F53\u8005\u96FB\u8A71\u5BFE\u5FDC\u5F8C"),
                                                "\u306B\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044")),
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "preferred_survey_date", className: "form-label" }, "\u8ABF\u67FB\u5E0C\u671B\u65E5\u6642"),
                                            React.createElement("div", { className: "input-group" },
                                                React.createElement("input", { id: "preferred_survey_date", type: "date", className: "form-control", placeholder: "\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", ...register('preferred_survey_date') }),
                                                React.createElement("input", { id: "preferred_survey_time", type: "time", className: "form-control", placeholder: "\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", ...register('preferred_survey_time') }))))),
                                React.createElement(If, { condition: project.estimate },
                                    React.createElement(Then, null,
                                        React.createElement("div", { className: "alert alert-info text-center" },
                                            React.createElement("p", { className: "m-0" },
                                                "\u4EE5\u4E0B\u306F",
                                                React.createElement("b", null, "\u898B\u7A4D\u4F5C\u6210\u5F8C"),
                                                "\u306B\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044")),
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "construction_date_from", className: "form-label" }, "\u65BD\u5DE5\u671F\u9593"),
                                            React.createElement("div", { className: "input-group" },
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "input-group" },
                                                        React.createElement("input", { type: "date", className: "form-control", id: "", placeholder: "\u65BD\u5DE5\u671F\u9593\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", ...register('construction_date_from') }),
                                                        React.createElement("input", { type: "time", className: "form-control", id: "", placeholder: "\u65BD\u5DE5\u671F\u9593\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", ...register('construction_time_from') }))),
                                                React.createElement("span", { style: {
                                                        margin: '3px auto',
                                                        transform: 'rotate(90deg)',
                                                    } }, "\u301C"),
                                                React.createElement("div", { className: "col-12" },
                                                    React.createElement("div", { className: "input-group" },
                                                        React.createElement("input", { type: "date", className: "form-control", id: "", placeholder: "\u65BD\u5DE5\u671F\u9593\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", ...register('construction_date_to') }),
                                                        React.createElement("input", { type: "time", className: "form-control", id: "", placeholder: "\u65BD\u5DE5\u671F\u9593\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", ...register('construction_time_to') }))))))),
                                React.createElement(If, { condition: true },
                                    React.createElement(Then, null,
                                        React.createElement("a", { className: "btn btn-system my-3", href: "#calendarCollapse", "data-bs-toggle": "collapse", role: "button", "aria-expanded": "false", "aria-controls": "calendarCollapse" }, "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u3092\u78BA\u8A8D"),
                                        React.createElement("div", { className: "collapse mb-3", id: "calendarCollapse" },
                                            React.createElement("span", { className: "event-survey event-icon", style: { margin: '0 0.5rem 0 0' } }),
                                            React.createElement("b", null, "\u73FE\u5834\u8ABF\u67FB"),
                                            React.createElement("span", { className: "event-construction event-icon", style: { margin: '0 0.5rem 0 0.5rem' } }),
                                            React.createElement("b", null, "\u5DE5\u4E8B"),
                                            React.createElement(ConstructionCalendar, null)))),
                                React.createElement("div", { className: "d-flex" },
                                    React.createElement("button", { className: "btn btn-system", type: "submit" },
                                        React.createElement("i", { className: "bi bi-arrow-clockwise me-2" }),
                                        ' ',
                                        id ? '更新' : '登録',
                                        "\u3059\u308B"))))))))));
};
export { ProjectForm };
