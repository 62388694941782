import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toDateTimeString } from '../../../lib/format';
import { http } from '../../../lib/httpClient';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
import { Layout } from '../../../layouts/Layout';
const MasterItemIndex = () => {
    const auth = useAtomValue(AuthAtom);
    const [items, setItems] = useState([]);
    useEffect(() => {
        http({ token: auth.token })
            .get('/items')
            .then((res) => {
            setItems(res.data.data);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u5546\u54C1" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h1", { className: "fw-bold" }),
                        React.createElement(Link, { className: "btn btn-system", to: "/masters/items/create" }, "\u65B0\u898F\u4F5C\u6210"))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "table-responsive" },
                            React.createElement("table", { className: "table align-middle" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "ID"),
                                        React.createElement("th", null, "\u540D\u524D"),
                                        React.createElement("th", null, "\u6570\u91CF"),
                                        React.createElement("th", null, "\u5358\u4F4D"),
                                        React.createElement("th", null, "\u91D1\u984D"),
                                        React.createElement("th", null, "\u66F4\u65B0\u65E5"),
                                        React.createElement("th", null, "\u64CD\u4F5C"))),
                                React.createElement("tbody", null, items.map((types) => (React.createElement("tr", { key: types.id },
                                    React.createElement("td", null, types.id),
                                    React.createElement("td", null,
                                        React.createElement("strong", null, types.name)),
                                    React.createElement("td", null, types.quantity),
                                    React.createElement("td", null, types.unit),
                                    React.createElement("td", null,
                                        types.price ? '¥' : '',
                                        types.price),
                                    React.createElement("td", null, toDateTimeString(types.updated_at)),
                                    React.createElement("td", { className: "d-flex flex-row gap-1" },
                                        React.createElement(Link, { to: `/masters/items/${types.id}/edit`, className: "btn btn-system" }, "\u7DE8\u96C6"),
                                        React.createElement("button", { type: "button", className: "btn btn-danger", onClick: () => {
                                                if (window.confirm('本当に削除してもよろしいですか？')) {
                                                    http({ token: auth.token })
                                                        .delete(`/items/${types.id}`)
                                                        .then(() => {
                                                        window.location.reload();
                                                    });
                                                }
                                            } }, "\u524A\u9664"))))))))))))));
};
export { MasterItemIndex };
