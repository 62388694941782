import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
import { Layout } from '../../../layouts/Layout';
const MasterBankForm = () => {
    const auth = useAtomValue(AuthAtom);
    const navigate = useNavigate();
    const { id } = useParams();
    const { register, handleSubmit, reset } = useForm();
    const onSubmit = (data) => {
        if (id) {
            http({ token: auth.token })
                .put(`/banks/${id}`, data)
                .then((res) => {
                if (res.data) {
                    navigate('/banks');
                }
                else {
                    alert('更新に失敗しました。');
                }
            });
        }
        else {
            http({ token: auth.token })
                .post('/banks', data)
                .then((res) => {
                if (res.data) {
                    navigate('/banks');
                }
                else {
                    alert('登録に失敗しました。');
                }
            });
        }
    };
    useEffect(() => {
        if (id) {
            http({ token: auth.token })
                .get(`/banks/${id}`)
                .then((res) => {
                reset(res.data.data);
            });
        }
    }, [id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: `口座情報${id ? '更新' : '登録'}` },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "name" }, "\u9280\u884C\u540D"),
                                React.createElement("input", { type: "text", className: "form-control", id: "name", placeholder: "\u9280\u884C\u540D", ...register('name') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "branch_name" }, "\u652F\u5E97\u540D"),
                                React.createElement("input", { type: "text", className: "form-control", id: "branch_name", placeholder: "\u652F\u5E97\u540D", ...register('branch_name') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "account_number" }, "\u53E3\u5EA7\u7A2E\u5225"),
                                React.createElement("select", { id: "", className: "form-select", ...register('account_type') },
                                    React.createElement("option", { value: "1" }, "\u666E\u901A"),
                                    React.createElement("option", { value: "2" }, "\u5F53\u5EA7"),
                                    React.createElement("option", { value: "9" }, "\u305D\u306E\u4ED6"))),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "account_number" }, "\u53E3\u5EA7\u756A\u53F7"),
                                React.createElement("input", { type: "text", className: "form-control", id: "account_number", placeholder: "\u53E3\u5EA7\u756A\u53F7", ...register('account_number') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "account_holder_name" }, "\u53E3\u5EA7\u540D\u7FA9"),
                                React.createElement("input", { type: "text", className: "form-control", id: "account_holder_name", placeholder: "\u53E3\u5EA7\u540D\u7FA9", ...register('account_holder_name') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "account_holder_name_kana" }, "\u53E3\u5EA7\u540D\u7FA9\uFF08\u30AB\u30CA\uFF09"),
                                React.createElement("input", { type: "text", className: "form-control", id: "account_holder_name_kana", placeholder: "\u53E3\u5EA7\u540D\u7FA9\uFF08\u30AB\u30CA\uFF09", ...register('account_holder_name_kana') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("input", { type: "checkbox", className: "form-check-input", id: "is_enabled", ...register('is_enabled') }),
                                React.createElement("label", { htmlFor: "is_enabled" }, "\u6709\u52B9")),
                            React.createElement("div", { className: "d-flex gap-1" },
                                React.createElement("button", { type: "submit", className: "btn btn-system" },
                                    id ? '更新' : '登録',
                                    "\u3059\u308B")))))))));
};
export { MasterBankForm };
