import React, { useEffect } from 'react';
import { http } from '../../lib/httpClient';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Layout } from '../../layouts/Layout';
import useSWR from 'swr';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../atoms/AuthAtom';
export const CustomerForm = () => {
    const auth = useAtomValue(AuthAtom);
    const navigate = useNavigate();
    const { id } = useParams();
    const { register, handleSubmit, reset, setValue } = useForm();
    const { data: channels, isLoading: isLoadingChannels, error: errorChannels, } = useSWR('/channels', (url) => http({ token: auth.token })
        .get(url)
        .then((res) => res.data.data));
    const onSubmit = (data) => {
        delete data.projects;
        if (id) {
            http({ token: auth.token })
                .patch(`/customers/${id}`, data)
                .then((res) => {
                navigate(`/customers/${id}`);
            })
                .catch((err) => {
                alert('更新に失敗しました。');
            });
        }
        else {
            http({ token: auth.token })
                .post('/customers', data)
                .then((res) => {
                navigate('/customers');
            })
                .catch((err) => {
                alert('登録に失敗しました。');
            });
        }
    };
    useEffect(() => {
        if (id) {
            const fetchCustomer = async () => {
                const { data } = await http({ token: auth.token }).get(`/customers/${id}`);
                reset(data.data);
            };
            fetchCustomer();
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: `顧客情報${id ? '更新' : '作成'}` },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("form", { className: "h-adr", onSubmit: handleSubmit(onSubmit) },
                            React.createElement("span", { className: "d-none p-country-name" }, "Japan"),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("p", { className: "fw-bold" },
                                    React.createElement("span", { className: "text-danger" }, "*"),
                                    "\u304C\u3064\u3044\u305F\u9805\u76EE\u306F\u5FC5\u9808\u3067\u3059")),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "name", className: "form-label" },
                                    React.createElement("span", { className: "text-danger fw-bold" }, "*"),
                                    "\u540D\u524D"),
                                React.createElement("input", { id: "name", type: "text", className: "form-control mb-1", placeholder: "\u540D\u524D", required: true, ...register('name_kanji', { required: true }) }),
                                React.createElement("input", { type: "text", className: "form-control mb-1", placeholder: "\u30D5\u30EA\u30AC\u30CA", ...register('name_kana') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "init_channel_id", className: "form-label" },
                                    React.createElement("span", { className: "text-danger fw-bold" }, "*"),
                                    "\u521D\u56DE\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB"),
                                React.createElement("select", { id: "init_channel_id", className: "form-select", ...register('init_channel_id', { required: true }) },
                                    React.createElement("option", { disabled: true }, "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
                                    channels?.map((channel) => (React.createElement("option", { key: channel.id, value: channel.id }, channel.name))))),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "tel", className: "form-label" },
                                    React.createElement("span", { className: "text-danger fw-bold" }, "*"),
                                    "\u96FB\u8A71\u756A\u53F7"),
                                React.createElement("input", { id: "tel", type: "tel", className: "form-control mb-1", placeholder: "\u96FB\u8A71\u756A\u53F71", ...register('tel_1') }),
                                React.createElement("input", { type: "tel", className: "form-control mb-1", placeholder: "\u96FB\u8A71\u756A\u53F72", ...register('tel_2') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "fax", className: "form-label" }, "Fax\u756A\u53F7"),
                                React.createElement("input", { id: "fax", type: "tel", className: "form-control mb-1", placeholder: "Fax1", ...register('fax_1') }),
                                React.createElement("input", { type: "tel", className: "form-control mb-1", placeholder: "Fax2", ...register('fax_2') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "person", className: "form-label" },
                                    React.createElement("span", { className: "text-danger fw-bold" }, "*"),
                                    "\u62C5\u5F53\u8005"),
                                React.createElement("input", { id: "person", type: "text", className: "form-control", placeholder: "\u62C5\u5F53\u8005", ...register('person') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "email", className: "form-label" }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
                                React.createElement("input", { id: "email", type: "email", className: "form-control", placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", ...register('email') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "website", className: "form-label" }, "\u30DB\u30FC\u30E0\u30DA\u30FC\u30B8"),
                                React.createElement("input", { id: "website", type: "url", className: "form-control", placeholder: "\u30DB\u30FC\u30E0\u30DA\u30FC\u30B8", ...register('website') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "postal_code", className: "form-label" },
                                    React.createElement("span", { className: "text-danger fw-bold" }, "*"),
                                    "\u90F5\u4FBF\u756A\u53F7"),
                                React.createElement("input", { id: "postal_code", type: "text", className: "form-control p-postal-code", placeholder: "\u90F5\u4FBF\u756A\u53F7", ...register('postal_code') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "address", className: "form-label" },
                                    React.createElement("span", { className: "text-danger fw-bold" }, "*"),
                                    "\u4F4F\u6240"),
                                React.createElement("input", { id: "address", type: "text", className: "form-control mb-1 p-region", placeholder: "\u90FD\u9053\u5E9C\u770C", ...register('address_1') }),
                                React.createElement("input", { type: "text", className: "form-control mb-1 p-locality", placeholder: "\u5E02\u533A\u753A\u6751", ...register('address_2') }),
                                React.createElement("input", { type: "text", className: "form-control mb-1 p-street-address", placeholder: "...", ...register('address_3') }),
                                React.createElement("input", { type: "text", className: "form-control mb-1 p-extended-address", placeholder: "...", ...register('address_4') })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "corporate_number", className: "form-label" }, "\u6CD5\u4EBA\u756A\u53F7"),
                                React.createElement("input", { id: "corporate_number", type: "text", className: "form-control", placeholder: "0000000000000", ...register('corporate_number'), onChange: (e) => {
                                        if (e.target.value.length === 13)
                                            setValue('invoice_registration_number', `T${e.target.value}`);
                                        else
                                            setValue('invoice_registration_number', '');
                                    } })),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "invoice_registration_number", className: "form-label" }, "\u9069\u683C\u8ACB\u6C42\u66F8\u767A\u884C\u4E8B\u696D\u8005\u756A\u53F7"),
                                React.createElement("input", { id: "invoice_registration_number", type: "text", className: "form-control", placeholder: "T0000000000000", ...register('invoice_registration_number') }),
                                React.createElement("small", { className: "d-block text-danger" }, "\u203B\u6CD5\u4EBA\u756A\u53F7\u304C\u5165\u529B\u3055\u308C\u305F\u5834\u5408\u306F\u81EA\u52D5\u5165\u529B\u3055\u308C\u307E\u3059\u3002")),
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("label", { htmlFor: "note", className: "form-label" }, "\u5099\u8003"),
                                React.createElement("textarea", { id: "note", className: "form-control", rows: 5, placeholder: "\u5099\u8003", ...register('note') })),
                            React.createElement("div", { className: "d-flex gap-3" },
                                React.createElement("button", { type: "submit", className: "btn btn-system" },
                                    React.createElement("i", { className: "bi bi-arrow-clockwise me-2" }),
                                    ' ',
                                    id ? '更新' : '登録',
                                    "\u3059\u308B")))))))));
};
