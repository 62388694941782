import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../../layouts/Layout";
const SalesIndex = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u58F2\u4E0A\u7BA1\u7406" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "d-flex flex-column gap-3 py-5" },
                            React.createElement(Link, { to: "/sales/Summary1", className: "shadow-sm p-3 border bg-white text-reset text-decoration-none" },
                                React.createElement("h2", { className: "m-0 h3 fw-bold" }, "\u671F\u9593\u6307\u5B9A\u30C7\u30FC\u30BF\u30EA\u30B9\u30C8"),
                                React.createElement("p", { className: "m-0 text-muted" }, "\u5168\u5BFE\u8C61\u30C7\u30FC\u30BF\u9805\u76EE")))))))));
};
export { SalesIndex };
