import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { Layout } from '../../../layouts/Layout';
const MasterChannelForm = () => {
    const auth = useAtomValue(AuthAtom);
    const navigate = useNavigate();
    const { id } = useParams();
    const { register, handleSubmit, reset } = useForm();
    const onSubmit = (data) => {
        if (id) {
            http({ token: auth.token })
                .put(`/channels/${id}`, data)
                .then((res) => {
                if (res.data) {
                    navigate('/masters/channels');
                }
                else {
                    alert('更新に失敗しました。');
                }
            });
        }
        else {
            http({ token: auth.token })
                .post('/channels', data)
                .then((res) => {
                if (res.data) {
                    navigate('/masters/channels');
                }
                else {
                    alert('登録に失敗しました。');
                }
            });
        }
    };
    useEffect(() => {
        if (id) {
            http({ token: auth.token })
                .get(`/channels/${id}`)
                .then((res) => {
                reset(res.data.data);
            });
        }
    }, [id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: `販売チャネル${id ? '編集' : '新規作成'}` },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h1", { className: "fw-bold" }),
                        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "name" }, "\u540D\u524D"),
                                React.createElement("input", { type: "text", className: "form-control", id: "name", placeholder: "\u6C0F\u540D", ...register('name') })),
                            React.createElement("button", { type: "submit", className: "btn btn-system" },
                                id ? '更新' : '登録',
                                "\u3059\u308B"))))))));
};
export { MasterChannelForm };
