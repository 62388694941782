import React from "react";
export default function DetailModal({ isOpen, data, setIsOpen }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bg-white border shadow px-3 py-5 position-fixed rounded", style: {
                maxWidth: "420px",
                width: "95%",
                zIndex: 100,
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%) ${isOpen ? "scale(1)" : "scale(0)"}`,
                visibility: isOpen ? "visible" : "hidden",
                transition: "visibility ease 0.15s, transform ease-in 0.15s",
            } },
            React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-center" },
                React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-start w-100 mb-3" },
                    React.createElement("div", { className: "mb-1" },
                        React.createElement("h5", { className: "fw-bold mb-0" }, data.name),
                        React.createElement("p", null, data.description)),
                    React.createElement("p", null,
                        React.createElement("span", { className: "fw-bold" }, "\u304A\u5BA2\u69D8:"),
                        " ",
                        data.customer?.name_kanji,
                        React.createElement("br", null),
                        React.createElement("span", { className: "fw-bold" }, "\u4F4F\u6240:"),
                        " ",
                        data.customer?.address_1,
                        data.customer?.address_2,
                        data.customer?.address_3,
                        data.customer?.address_4,
                        React.createElement("br", null),
                        React.createElement("span", { className: "fw-bold" }, "\u96FB\u8A71\u756A\u53F7:"),
                        " ",
                        data.customer?.tel_1,
                        React.createElement("br", null),
                        React.createElement("span", { className: "fw-bold" }, "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB\u53D7\u4ED8\u62C5\u5F53\u8005:"),
                        " ",
                        data.ch_reception_person,
                        React.createElement("span", { className: "fw-bold" }, "\u81EA\u793E\u53D7\u4ED8\u62C5\u5F53\u8005:"),
                        " ",
                        data.in_reception_person,
                        React.createElement("br", null),
                        React.createElement("span", { className: "fw-bold" }, "\u53D7\u4ED8\u65E5:"),
                        " ",
                        new Date(data.reception_date).toLocaleDateString("ja-JP"),
                        React.createElement("br", null),
                        React.createElement("span", { className: "fw-bold" }, "\u5DE5\u4E8B\u671F\u9593:"),
                        " ",
                        data.construction_date_from &&
                            new Date(data.construction_date_from).toLocaleDateString("ja-JP"),
                        "\u301C",
                        data.construction_date_to &&
                            new Date(data.construction_date_to).toLocaleDateString("ja-JP"),
                        React.createElement("br", null),
                        React.createElement("span", { className: "fw-bold" }, "\u99D0\u8ECA\u5834\u5229\u7528\u53EF\u5426:"),
                        " ",
                        data.is_parking_available ? "可" : "不可",
                        React.createElement("br", null)),
                    React.createElement("a", { className: "btn btn-system", href: `/projects/details/${data.id}` }, "\u6848\u4EF6\u30DA\u30FC\u30B8\u3078")),
                window.innerWidth > 768 ? (React.createElement("button", { type: "button", className: "btn btn-sm btn-danger fw-bold", onClick: () => setIsOpen(false), style: {
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                    } },
                    React.createElement("i", { className: "bi bi-x-lg" }))) : (React.createElement("button", { className: "btn btn-outline-danger", onClick: () => setIsOpen(false) },
                    React.createElement("i", { className: "bi bi-x-lg me-1" }),
                    "\u9589\u3058\u308B"))))));
}
