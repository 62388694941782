import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { Layout } from '../../../layouts/Layout';
const MasterChannelIndex = () => {
    const auth = useAtomValue(AuthAtom);
    const [channels, setChannels] = useState([]);
    useEffect(() => {
        http({ token: auth.token })
            .get('/channels')
            .then((res) => {
            setChannels(res.data.data);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h1", { className: "fw-bold" }),
                        React.createElement(Link, { to: "/masters/channels/create", className: "btn btn-system" }, "\u65B0\u898F\u4F5C\u6210"))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("table", { className: "table align-middle" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", null, "ID"),
                                    React.createElement("th", null, "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB\u540D"),
                                    React.createElement("th", null, "\u64CD\u4F5C"))),
                            React.createElement("tbody", null, channels.map((channel) => (React.createElement("tr", { key: channel.id },
                                React.createElement("td", null, channel.id),
                                React.createElement("td", null, channel.name),
                                React.createElement("td", null,
                                    React.createElement("div", { className: "btn-group" },
                                        React.createElement(Link, { to: `/masters/channels/${channel.id}/edit`, className: "btn btn-system" }, "\u7DE8\u96C6"),
                                        React.createElement("button", { type: "button", className: "btn btn-danger", onClick: () => {
                                                if (window.confirm('本当に削除してもよろしいですか？')) {
                                                    http({ token: auth.token })
                                                        .delete(`/channels/${channel.id}`)
                                                        .then(() => {
                                                        window.location.reload();
                                                    });
                                                }
                                            } }, "\u524A\u9664"))))))))))))));
};
export { MasterChannelIndex };
