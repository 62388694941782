import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Layout } from "../../layouts/Layout";
const SalesSummary1 = () => {
    const [params] = useSearchParams();
    console.log(params.get("from"), params.get("to"));
    const [invoices, setInvoices] = useState([]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u58F2\u4E0A\u7BA1\u7406" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mb-5" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h2", { className: "fw-bold mb-3" }, "\u671F\u9593\u6307\u5B9A\u30C7\u30FC\u30BF\u30EA\u30B9\u30C8"),
                        React.createElement(Link, { to: "/sales", className: "btn btn-secondary" }, "\u58F2\u4E0A\u7BA1\u7406\u30C8\u30C3\u30D7\u306B\u623B\u308B"))),
                React.createElement("div", { className: "row mb-5" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("p", null),
                        React.createElement("form", { method: "GET" },
                            React.createElement("div", { className: "d-flex flex-row gap-3 align-items-center mb-3" },
                                React.createElement("input", { type: "date", name: "date_from", id: "", className: "form-control", defaultValue: params.get("from") ??
                                        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                                            .toISOString()
                                            .slice(0, 10) }),
                                React.createElement("span", null, "\u301C"),
                                React.createElement("input", { type: "date", name: "date_to", id: "", className: "form-control", defaultValue: params.get("to") ??
                                        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                                            .toISOString()
                                            .slice(0, 10) }),
                                React.createElement("button", { type: "submit", className: "btn btn-system w-25" }, "\u691C\u7D22"))),
                        React.createElement("div", { className: "d-flex flex-row gap-3" },
                            React.createElement("button", { type: "button", className: "btn btn-system" }, "\u96C6\u8A08\u30C7\u30FC\u30BF\u3092\u30C0\u30A6\u30F3\u30ED\u30FC\u30C9"))))))));
};
export { SalesSummary1 };
