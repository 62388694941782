import React, { useState } from 'react';
import Splash from '../template/components/Splash';
import { useForm } from 'react-hook-form';
import { http } from '../lib/httpClient';
import { useAtom } from 'jotai';
import { AuthAtom } from '../atoms/AuthAtom';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../components/Loading';
import { toast } from 'react-toastify';
const LoginPage = () => {
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm();
    const [, setAuth] = useAtom(AuthAtom);
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = (data) => {
        setIsLoading(true);
        http()
            .post('/auth/login', data)
            .then((res) => {
            setAuth({
                token: res.data.token,
                user: res.data.user,
            });
            navigate('/');
            toast.success(`${res.data.user.name}としてログインしました。`);
        })
            .catch((err) => {
            alert('ログインに失敗しました。');
            console.log(err);
            setIsLoading(false);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Loading, { isLoading: isLoading }),
        React.createElement(Splash, null),
        React.createElement("div", { className: "bg-system d-flex justify-content-center align-items-center vh-100" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row justify-content-center" },
                    React.createElement("div", { className: "col-lg-5 col-md-12 col-sm-12" },
                        React.createElement("div", { className: "card shadow" },
                            React.createElement("div", { className: "card-header fw-bold" }, "\u30ED\u30B0\u30A4\u30F3"),
                            React.createElement("div", { className: "card-body" },
                                React.createElement("div", { className: "d-flex justify-content-center align-items-center" },
                                    React.createElement("form", { className: "w-100", onSubmit: handleSubmit(onSubmit) },
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "username", className: "form-label" }, "ID"),
                                            React.createElement("input", { id: "username", type: "username", className: "form-control", required: true, autoComplete: "username", autoFocus: true, ...register('username') })),
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("label", { htmlFor: "password", className: "form-label" }, "\u30D1\u30B9\u30EF\u30FC\u30C9"),
                                            React.createElement("input", { id: "password", type: "password", className: "form-control", required: true, autoComplete: "current-password", ...register('password') })),
                                        React.createElement("div", { className: "text-center" },
                                            React.createElement("button", { type: "submit", className: "btn btn-warning d-inline-block" }, "\u30ED\u30B0\u30A4\u30F3"))))))))))));
};
export { LoginPage };
