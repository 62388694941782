import React from 'react';
import { http } from '../../lib/httpClient';
import { Else, If, Then } from 'react-if';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ProjectCard } from '../../components/pages/customers';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../atoms/AuthAtom';
import { Layout } from '../../layouts/Layout';
import useSWR from 'swr';
import { Loading } from '../../components/Loading';
import { Paginator } from '../../components/Paginator';
const ProjectIndex = () => {
    const [params, setParams] = useSearchParams();
    const { register, handleSubmit } = useForm();
    const auth = useAtomValue(AuthAtom);
    const { data: channels, isLoading: isLoadingChannels, error: errorChannels, } = useSWR('/channels', (url) => http({ token: auth.token })
        .get(url)
        .then((res) => res.data.data));
    const { data: projects, isLoading: isLoadingProjects, error: errorProjects, } = useSWR(`/projects?${params}`, (url) => http({ token: auth.token })
        .get(url)
        .then((res) => res.data));
    const onSubmit = (data) => {
        params.set('keyword', data.keyword);
        params.set('channel_id', data.channel_id.toString());
        setParams(params);
    };
    const handlePageChange = (page) => {
        params.set('page', page.toString());
        setParams(params);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u6848\u4EF6\u691C\u7D22" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "d-flex flex-column" },
                            React.createElement("div", { className: "w-100 mb-5" },
                                React.createElement(If, { condition: isLoadingProjects || isLoadingChannels },
                                    React.createElement(Then, null,
                                        React.createElement(Loading, null)),
                                    React.createElement(Else, null,
                                        React.createElement(If, { condition: errorProjects },
                                            React.createElement(Then, null,
                                                React.createElement("p", { className: "text-danger" }, "\u30A8\u30E9\u30FC\u304C\u767A\u751F\u3057\u307E\u3057\u305F")),
                                            React.createElement(Else, null,
                                                React.createElement(If, { condition: projects?.data?.length },
                                                    React.createElement(Then, null,
                                                        React.createElement("p", null,
                                                            projects?.meta?.total,
                                                            "\u4EF6\u306E\u6848\u4EF6\u304C\u898B\u3064\u304B\u308A\u307E\u3057\u305F\u3002")),
                                                    React.createElement(Else, null,
                                                        React.createElement("p", null, "\u6848\u4EF6\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3067\u3057\u305F\u3002"))))))),
                                React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                                    React.createElement("div", { className: "input-group mb-3" },
                                        React.createElement("input", { type: "search", className: "form-control", placeholder: "\u304A\u5BA2\u69D8\u540D\u307E\u305F\u306F\u96FB\u8A71\u756A\u53F7", defaultValue: params.get('keyword') || '', ...register('keyword') }),
                                        React.createElement("button", { type: "submit", className: "btn btn-warning px-4" },
                                            React.createElement("i", { className: "bi bi-search me-2" }),
                                            "\u691C\u7D22")),
                                    React.createElement("div", { className: "input-group mb-3" },
                                        React.createElement("select", { className: "form-select", ...register('channel_id'), defaultValue: params.get('channel_id') || '', onChange: (e) => {
                                                params.set('channel_id', e.target.value);
                                                setParams(params);
                                            } },
                                            React.createElement("option", { disabled: true }, "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB"),
                                            React.createElement("option", { value: "" }, "\u6307\u5B9A\u306A\u3057"),
                                            channels?.map((channel) => (React.createElement("option", { key: channel.id, value: channel.id }, channel.name))))))),
                            React.createElement("div", { className: "w-100" },
                                projects?.data?.map((project) => (React.createElement(ProjectCard, { key: project.id, project: project, withCustomerInfo: true }))),
                                React.createElement(Paginator, { meta: projects?.meta, onPageChange: handlePageChange })))))))));
};
export { ProjectIndex };
