import React from 'react';
export const Paginator = ({ meta, onPageChange, }) => {
    if (!meta)
        return null;
    const { current_page, last_page } = meta;
    return (React.createElement("div", { className: "d-flex flex-row justify-content-center" },
        React.createElement("nav", { "aria-label": "Page navigation example" },
            React.createElement("ul", { className: "pagination" },
                React.createElement("li", { className: "page-item" },
                    React.createElement("button", { className: `page-link ${current_page === 1 ? 'disabled' : ''}`, onClick: () => onPageChange(current_page - 1), disabled: current_page === 1 }, "\u00AB")),
                [...Array(last_page)].map((_, i) => (React.createElement("li", { className: "page-item", key: i },
                    React.createElement("button", { className: `page-link ${current_page === i + 1 ? 'active' : ''}`, onClick: () => onPageChange(i + 1), disabled: current_page === i + 1 }, i + 1)))),
                React.createElement("li", { className: "page-item" },
                    React.createElement("button", { className: `page-link ${current_page === last_page ? 'disabled' : ''}`, onClick: () => onPageChange(current_page + 1), disabled: current_page === last_page }, "\u00BB"))))));
};
