import React, { useRef, useState } from "react";
import { Octokit } from "octokit";
import { Link } from "react-router-dom";
const octokit = new Octokit({
    auth: process.env.MIX_GITHUB_TOKEN,
});
const createIssue = ({ title, body, }) => {
    if (!title || !body) {
        alert("タイトルと本文を入力してください");
        return false;
    }
    octokit.rest.issues
        .create({
        owner: "novalumo",
        repo: "aspia-system",
        title: title,
        body: body,
        labels: ["feedback"],
    })
        .then(() => {
        alert("フィードバックが送信されました");
        return true;
    })
        .catch((error) => {
        console.error(error);
        alert("フィードバックの送信に失敗しました");
        return false;
    });
    return true;
};
const FeedbackIndex = () => {
    const titleRef = useRef(null);
    const bodyRef = useRef(null);
    const [isEnable, setIsEnable] = useState(true);
    return (React.createElement("div", { className: "container my-5" },
        React.createElement("div", { className: "row justify-content-center" },
            React.createElement("div", { className: "col-md-8" },
                React.createElement("div", { className: "card" },
                    React.createElement("div", { className: "card-header fw-bold" }, "\u30D5\u30A3\u30FC\u30C9\u30D0\u30C3\u30AF\u3092\u9001\u4FE1\u3059\u308B"),
                    React.createElement("div", { className: "card-body" },
                        React.createElement("div", { className: "mb-3" },
                            React.createElement("label", { htmlFor: "feedback-title" }, "\u30BF\u30A4\u30C8\u30EB"),
                            React.createElement("input", { type: "text", id: "feedback-title", className: "form-control", ref: titleRef, placeholder: "\u30BF\u30A4\u30C8\u30EB\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", disabled: !isEnable })),
                        React.createElement("div", { className: "mb-3" },
                            React.createElement("label", { htmlFor: "feedback-body" }, "\u672C\u6587"),
                            React.createElement("textarea", { name: "", id: "feedback-body", className: "form-control", cols: 30, rows: 10, ref: bodyRef, placeholder: "\u672C\u6587\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044", disabled: !isEnable })),
                        React.createElement("button", { className: "btn btn-system px-3 mx-1", onClick: () => {
                                setIsEnable(false);
                                if (createIssue({
                                    title: titleRef.current?.value,
                                    body: bodyRef.current?.value,
                                })) {
                                    setIsEnable(false);
                                }
                                else {
                                    setIsEnable(true);
                                }
                            } }, "\u9001\u4FE1"),
                        React.createElement(Link, { to: "/", className: "btn btn-secondary" }, "\u30C8\u30C3\u30D7\u30DA\u30FC\u30B8\u306B\u623B\u308B")))))));
};
export { FeedbackIndex };
