import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
/**
 * スプラッシュ画面
 */
function Splash() {
    const [isShowing, setIsShowing] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsShowing(false);
        }, 2000);
    }, []);
    return (React.createElement("div", { className: `bg-system vw-100 d-block splash__wrapper`, style: {
            opacity: isShowing ? 1 : 0,
            visibility: isShowing ? "visible" : "hidden",
            transition: "0.3s",
        } },
        React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-center vh-100" },
            React.createElement("img", { src: `/img/logo.png`, className: "mw-75 m mx-auto mb-3 splash__logo", alt: "logo" }),
            React.createElement("p", { className: "text-white h5 splash__text" }, "\u767D\u30A2\u30EA\u99C6\u9664\u30FB\u5E8A\u4E0B\u63DB\u6C17\u88C5\u7F6E\u30FB\u8010\u9707\u5BFE\u7B56"))));
}
export default Splash;
const container = document.getElementById("splash");
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(Splash, null));
}
