import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
import { Layout } from '../../../layouts/Layout';
const MasterRoleIndex = () => {
    const auth = useAtomValue(AuthAtom);
    const [roles, setRoles] = useState([]);
    useEffect(() => {
        http({ token: auth.token })
            .get('/roles')
            .then((data) => {
            setRoles(data.data.data);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u5F79\u5272\u30FB\u6A29\u9650\u7BA1\u7406" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h1", { className: "fw-bold" }),
                        React.createElement(Link, { to: "/masters/roles/create", className: "btn btn-system" }, "\u5F79\u5272\u8FFD\u52A0"))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "table-responsive" },
                            React.createElement("table", { className: "table align-middle" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "ID"),
                                        React.createElement("th", null, "\u8868\u793A\u540D"),
                                        React.createElement("th", null, "\u64CD\u4F5C"))),
                                React.createElement("tbody", null, roles.map((role) => (React.createElement("tr", { key: role.id },
                                    React.createElement("td", null, role.id),
                                    React.createElement("td", null,
                                        React.createElement("strong", null, role.display_name)),
                                    React.createElement("td", { className: "d-flex flex-row gap-1" },
                                        React.createElement(Link, { to: `/masters/roles/${role.id}/edit`, className: "btn btn-system" }, "\u7DE8\u96C6"),
                                        React.createElement("button", { type: "button", className: "btn btn-danger", onClick: () => {
                                                if (window.confirm('本当に削除してもよろしいですか？')) {
                                                    http({ token: auth.token })
                                                        .delete(`/roles/${role.id}`)
                                                        .then((res) => {
                                                        if (res.data) {
                                                            window.location.reload();
                                                        }
                                                        else {
                                                            alert('削除に失敗しました。');
                                                        }
                                                    });
                                                }
                                            } }, "\u524A\u9664"))))))))))))));
};
export { MasterRoleIndex };
