import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import "moment/locale/ja";
import { Calendar as BigCalendar, momentLocalizer, Views, } from "react-big-calendar";
import "../../../css/react-big-calendar.css";
import DetailModal from "./CalendarDetailModal";
import { createRoot } from "react-dom/client";
export default function ConstructionCalendar() {
    const [schedule, setSchedule] = useState([]);
    const [events, setEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState({});
    async function getSchedule() {
        const res = await axios.get(`/schedules/get`);
        setSchedule(res.data);
        // transform data to event
        const events = res.data.map((item, index) => {
            return {
                id: index + 1,
                title: item.title,
                start: moment(item.start).toDate(),
                end: moment(item.end).toDate(),
                allDay: item.allDay,
                type: item.type,
                project: item.project,
            };
        });
        setEvents(events);
        console.log(events);
    }
    function setColorByType(type) {
        switch (type) {
            case "construction":
                return "#ffc107";
            case "survey":
                return "#28a745";
            default:
                return "#333333";
        }
    }
    useEffect(() => {
        getSchedule();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "d-flex flex-row justify-content-center align-items-center w-100" },
            React.createElement(BigCalendar, { localizer: momentLocalizer(moment), events: events, timeslots: 1, step: 30, defaultView: Views.MONTH, defaultDate: new Date(), eventPropGetter: (event) => {
                    return {
                        style: {
                            backgroundColor: setColorByType(event.type),
                            borderColor: setColorByType(event.type),
                        },
                    };
                }, onSelectEvent: (event) => {
                    setIsModalOpen(true);
                    setData(event.project);
                }, views: ["month", "week", "day"], formats: {
                    dateFormat: "D",
                    monthHeaderFormat: "YYYY年M月",
                    dayHeaderFormat: "M月D日(ddd)",
                } })),
        React.createElement(DetailModal, { isOpen: isModalOpen, data: data, setIsOpen: setIsModalOpen })));
}
const container = document.getElementById("construction-calendar");
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(ConstructionCalendar, null));
}
