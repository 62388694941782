import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { Layout } from '../../../layouts/Layout';
const MasterBranchIndex = () => {
    const auth = useAtomValue(AuthAtom);
    const [channels, setChannels] = useState([]);
    const [branches, setBranches] = useState([]);
    const [channelId, setChannelId] = useState(null);
    useEffect(() => {
        http({ token: auth.token })
            .get('/channels')
            .then((res) => {
            setChannels(res.data.data);
        });
    }, []);
    useEffect(() => {
        http({ token: auth.token })
            .get('/branches', {
            params: {
                channel_id: channelId,
            },
        })
            .then((res) => {
            setBranches(res.data.data);
        });
    }, [channelId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u652F\u5E97" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("h1", { className: "fw-bold" }),
                        React.createElement(Link, { to: "/masters/branches/create", className: "btn btn-system" }, "\u65B0\u898F\u4F5C\u6210"))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("select", { name: "channel_id", id: "channel_id", className: "form-select mb-1", onChange: (e) => {
                                console.log(e.target.value);
                                setChannelId(Number(e.target.value));
                            } },
                            React.createElement("option", { value: "" }, "\u5168\u3066"),
                            channels?.map((channel) => (React.createElement("option", { key: channel.id, value: channel.id }, channel.name)))))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("table", { className: "table align-middle" },
                            React.createElement("thead", null,
                                React.createElement("tr", null,
                                    React.createElement("th", null, "\u5E97\u30B3\u30FC\u30C9"),
                                    React.createElement("th", null, "\u652F\u5E97\u540D"),
                                    React.createElement("th", null, "\u4F4F\u6240"),
                                    React.createElement("th", null, "\u64CD\u4F5C"))),
                            React.createElement("tbody", null, branches.map((branch) => (React.createElement("tr", { key: branch.id },
                                React.createElement("td", null, branch.store_code),
                                React.createElement("td", null, branch.name),
                                React.createElement("td", null,
                                    branch.address_1,
                                    branch.address_2,
                                    branch.address_3,
                                    branch.address_4),
                                React.createElement("td", null,
                                    React.createElement(Link, { to: `/masters/branches/${branch.id}/edit`, className: "btn btn-system" }, "\u7DE8\u96C6"),
                                    React.createElement("form", { id: "branchDeleteForm-{ branch.id }", action: "{ route('masters.branches.delete', ['branch_id' => branch.id]) }", method: "POST", className: "d-inline" },
                                        React.createElement("button", { type: "button", className: "btn btn-danger", onClick: () => {
                                                if (window.confirm('本当に削除してもよろしいですか？')) {
                                                    http({ token: auth.token })
                                                        .delete(`/branches/${branch.id}`)
                                                        .then((res) => {
                                                        window.location.reload();
                                                    });
                                                }
                                            } }, "\u524A\u9664")))))))))),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Link, { to: "/masters", className: "btn btn-secondary" }, "\u30DE\u30B9\u30BF\u4E00\u89A7\u306B\u623B\u308B")))))));
};
export { MasterBranchIndex };
