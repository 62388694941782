import React from "react";
import { Link, useNavigate } from "react-router-dom";
const ScreenTitle = ({ title, prevPage, }) => {
    const navigate = useNavigate();
    return (React.createElement("div", null,
        React.createElement("div", { className: "bg-system text-white px-3 py-2" },
            React.createElement("div", { className: "d-flex flex-row align-items-center gap-3" },
                React.createElement("div", { className: "btn-group", role: "group" },
                    React.createElement("button", { type: "button", className: "btn btn-outline-light", onClick: () => (prevPage ? navigate(prevPage) : navigate(-1)) }, "\u2190"),
                    React.createElement(Link, { to: "/", className: "btn btn-outline-light" }, "\u30DB\u30FC\u30E0")),
                React.createElement("h1", { className: "text-center h3 fw-bold m-0" }, title)))));
};
const ScreenTitleSmall = ({ title }) => {
    return (React.createElement("div", { className: "page-title shadow-sm" },
        React.createElement("p", { className: "fw-bold" }, title)));
};
export { ScreenTitle, ScreenTitleSmall };
