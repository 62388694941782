import React, { useEffect, useState } from 'react';
import { Case, Else, If, Switch, Then } from 'react-if';
import { Link, useParams } from 'react-router-dom';
import { http } from '../../lib/httpClient';
import { ClaimCard } from '../../components/pages/projects';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../atoms/AuthAtom';
import { Layout } from '../../layouts/Layout';
const ProjectDetails = () => {
    const { id } = useParams();
    const [status, setStatus] = useState(0);
    const [project, setProject] = useState({});
    const auth = useAtomValue(AuthAtom);
    useEffect(() => {
        const getProject = async () => {
            const { data } = await http({ token: auth.token }).get(`/projects/${id}`);
            setProject(data.data);
        };
        getProject();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u6848\u4EF6\u60C5\u5831" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-start" },
                            React.createElement("section", { className: "w-100 mb-5" },
                                React.createElement("p", { className: "fw-bold text-muted mb-1" },
                                    project?.customer?.name_kanji,
                                    " \u69D8"),
                                React.createElement("h1", { className: "fw-bold" }, project?.name),
                                React.createElement("p", { className: "fw-bold text-muted" },
                                    "\u6848\u4EF6ID: ",
                                    project?.id),
                                React.createElement("p", { className: "fw-bold" },
                                    "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB: ",
                                    project?.channel?.name,
                                    React.createElement("br", null),
                                    "\u652F\u5E97: ",
                                    project?.branch?.name,
                                    React.createElement("br", null),
                                    "\u53D7\u4ED8\u62C5\u5F53\u8005: ",
                                    project?.ch_reception_person),
                                React.createElement("div", { className: "mb-3" },
                                    React.createElement("div", { className: "btn-group" },
                                        React.createElement(Link, { to: `/projects/${project?.id}/edit`, className: "btn btn-system" },
                                            React.createElement("i", { className: "bi bi-pencil-square me-2" }),
                                            "\u6848\u4EF6\u60C5\u5831\u7DE8\u96C6"),
                                        React.createElement("button", { type: "button", className: "btn btn-danger", "data-bs-toggle": "modal", "data-bs-target": "#deleteModal" },
                                            React.createElement("i", { className: "bi bi-trash-fill me-2" }),
                                            "\u524A\u9664"))),
                                React.createElement("div", { className: "btn-group" },
                                    React.createElement(Link, { to: `/surveys/${project?.id}`, className: "btn btn-system { project?.isSetSurveyDate() ? '' : 'disabled' }" }, "\u73FE\u5834\u8ABF\u67FB\u5165\u529B"),
                                    React.createElement(Link, { to: `/estimates/${project?.id}`, className: "btn btn-system { $has_survey ? '' : 'disabled' }" }, "\u898B\u7A4D"),
                                    React.createElement(Link, { to: `/invoices/${project?.id}`, className: "btn btn-system { project?.isSetConstructionDate() ? '' : 'disabled' }" }, "\u8ACB\u6C42"))),
                            React.createElement("section", { className: "w-100 mb-5" },
                                React.createElement("h2", { className: "fw-bold mb-3" }, "\u76F8\u8AC7\u5185\u5BB9"),
                                React.createElement("p", { className: "" }, project?.description)),
                            React.createElement("section", { className: "w-100" },
                                React.createElement("div", { className: "mb-5" },
                                    React.createElement("h2", { className: "fw-bold" }, "\u30AF\u30EC\u30FC\u30E0\u7BA1\u7406"),
                                    React.createElement(Link, { to: `/claims/${project?.id}/create`, className: "btn btn-warning" }, "\u65B0\u898F\u30AF\u30EC\u30FC\u30E0")),
                                React.createElement("ul", { className: "nav nav-pills nav-justified flex-column flex-md-row mb-3", id: "pills-tab", role: "tablist" },
                                    React.createElement("li", { className: "nav-item", role: "presentation" },
                                        React.createElement("button", { onClick: () => setStatus(0), className: `nav-link ${status === 0 ? 'active' : ''}` },
                                            React.createElement("i", { className: "bi bi-exclamation-circle-fill me-2" }),
                                            "\u672A\u51E6\u7406")),
                                    React.createElement("li", { className: "nav-item", role: "presentation" },
                                        React.createElement("button", { onClick: () => setStatus(1), className: `nav-link ${status === 1 ? 'active' : ''}` },
                                            React.createElement("i", { className: "bi bi-check-circle-fill me-2" }),
                                            "\u51E6\u7406\u6E08\u307F")),
                                    React.createElement("li", { className: "nav-item", role: "presentation" },
                                        React.createElement("button", { onClick: () => setStatus(2), className: `nav-link ${status === 2 ? 'active' : ''}` },
                                            React.createElement("i", { className: "bi bi-x-circle-fill me-2" }),
                                            "\u30AD\u30E3\u30F3\u30BB\u30EB"))),
                                project.claims && (React.createElement("div", null,
                                    React.createElement("p", null,
                                        React.createElement(If, { condition: project?.claims?.length < 1 },
                                            React.createElement(Then, null,
                                                React.createElement(Switch, null,
                                                    React.createElement(Case, { condition: status === 0 }, "\u672A\u51E6\u7406\u306E"),
                                                    React.createElement(Case, { condition: status === 1 }, "\u51E6\u7406\u6E08\u307F\u306E"),
                                                    React.createElement(Case, { condition: status === 2 }, "\u30AD\u30E3\u30F3\u30BB\u30EB\u3055\u308C\u305F")),
                                                "\u30AF\u30EC\u30FC\u30E0\u306F\u3042\u308A\u307E\u305B\u3093\u3002"),
                                            React.createElement(Else, null,
                                                React.createElement("span", { className: "fw-bold" },
                                                    project.claims.filter((claim) => claim.status === status).length,
                                                    "\u4EF6"),
                                                "\u306E",
                                                React.createElement(Switch, null,
                                                    React.createElement(Case, { condition: status === 0 }, "\u672A\u51E6\u7406"),
                                                    React.createElement(Case, { condition: status === 1 }, "\u51E6\u7406\u6E08\u307F"),
                                                    React.createElement(Case, { condition: status === 2 }, "\u30AD\u30E3\u30F3\u30BB\u30EB\u3055\u308C\u305F")),
                                                "\u30AF\u30EC\u30FC\u30E0\u304C\u3042\u308A\u307E\u3059"))),
                                    project.claims
                                        .filter((claim) => claim.status === status)
                                        .map((claim) => (React.createElement(ClaimCard, { key: claim.id, claim: claim })))))))))))));
};
export { ProjectDetails };
