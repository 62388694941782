import React from 'react';
export const Loading = ({ isLoading = true }) => {
    const size = '5rem';
    return isLoading ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "w-100", style: {
                backgroundColor: 'rgba(255, 255, 255, .5)',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 100,
            } },
            React.createElement("div", { className: "d-flex flex-row justify-content-center align-items-center vh-100" },
                React.createElement("div", { className: "spinner-border text-info", style: { width: size, height: size }, role: "status" },
                    React.createElement("span", { className: "visually-hidden" }, "Loading...")))))) : null;
};
