import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { useForm } from 'react-hook-form';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { Layout } from '../../../layouts/Layout';
const MasterBranchForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { register, handleSubmit, reset } = useForm();
    const auth = useAtomValue(AuthAtom);
    const [channels, setChannels] = useState([]);
    useEffect(() => {
        http({ token: auth.token })
            .get('/channels')
            .then((res) => {
            setChannels(res.data.data);
            if (id) {
                http({ token: auth.token })
                    .get(`/branches/${id}`)
                    .then((res) => {
                    reset(res.data);
                });
            }
        });
    }, []);
    const onSubmit = (data) => {
        if (id) {
            http({ token: auth.token })
                .patch(`/branches/${id}`, data)
                .then((res) => {
                navigate('/masters/branches');
            })
                .catch((err) => {
                alert('更新に失敗しました。');
            });
        }
        else {
            http({ token: auth.token })
                .post('/branches', data)
                .then((res) => {
                navigate('/masters/branches');
            })
                .catch((err) => {
                alert('登録に失敗しました。');
            });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: `支店情報${id ? '編集' : '登録'}` },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "store_code" }, "\u5E97\u30B3\u30FC\u30C9"),
                                React.createElement("input", { type: "text", className: "form-control", id: "store_code", placeholder: "xxxx", ...register('store_code') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "name" }, "\u5E97\u8217\u540D"),
                                React.createElement("input", { type: "text", className: "form-control", id: "name", placeholder: "\u5E97\u8217\u540D", ...register('name') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "channel_id" }, "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB\u3078\u306E\u7D10\u4ED8\u3051"),
                                React.createElement("select", { id: "channel_id", className: "form-select", required: true, ...register('channel_id') },
                                    React.createElement("option", { disabled: true }, "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
                                    channels?.map((channel) => (React.createElement("option", { key: channel.id, value: channel.id }, channel.name))))),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "postal_code" }, "\u90F5\u4FBF\u756A\u53F7"),
                                React.createElement("input", { type: "text", className: "form-control", id: "postal_code", placeholder: "\u90F5\u4FBF\u756A\u53F7", ...register('postal_code') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "address_1" }, "\u4F4F\u6240"),
                                React.createElement("input", { type: "text", className: "form-control mb-1", id: "address_1", placeholder: "\u4F4F\u6240", ...register('address_1') }),
                                React.createElement("input", { type: "text", className: "form-control mb-1", id: "address_2", placeholder: "\u4F4F\u6240", ...register('address_2') }),
                                React.createElement("input", { type: "text", className: "form-control mb-1", id: "address_3", placeholder: "\u4F4F\u6240", ...register('address_3') }),
                                React.createElement("input", { type: "text", className: "form-control mb-1", id: "address_4", placeholder: "\u4F4F\u6240", ...register('address_4') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "tel_1" }, "\u96FB\u8A71\u756A\u53F71"),
                                React.createElement("input", { type: "text", className: "form-control", id: "tel_1", placeholder: "\u96FB\u8A71\u756A\u53F71", ...register('tel_1') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "tel_2" }, "\u96FB\u8A71\u756A\u53F72"),
                                React.createElement("input", { type: "text", className: "form-control", id: "tel_2", placeholder: "\u96FB\u8A71\u756A\u53F72", ...register('tel_2') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "tel_3" }, "\u96FB\u8A71\u756A\u53F73"),
                                React.createElement("input", { type: "text", className: "form-control", id: "tel_3", placeholder: "\u96FB\u8A71\u756A\u53F73", ...register('tel_3') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "fax_1" }, "Fax\u756A\u53F71"),
                                React.createElement("input", { type: "text", className: "form-control", id: "fax_1", placeholder: "Fax\u756A\u53F71", ...register('fax_1') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "fax_2" }, "Fax\u756A\u53F72"),
                                React.createElement("input", { type: "text", className: "form-control", id: "fax_2", placeholder: "Fax\u756A\u53F72", ...register('fax_2') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "fax_3" }, "Fax\u756A\u53F73"),
                                React.createElement("input", { type: "text", className: "form-control", id: "fax_3", placeholder: "Fax\u756A\u53F73", ...register('fax_3') })),
                            React.createElement("button", { type: "submit", className: "btn btn-system" },
                                id ? '更新' : '登録',
                                "\u3059\u308B"))))))));
};
export { MasterBranchForm };
