import axios from "axios";
/**
 * Make a branch select element with the given options.
 */
function makeBranchSelector(selectElement, selectedBranchId = 0) {
    const index = selectElement.selectedIndex;
    const select = document.getElementById("branch_id");
    select.innerHTML = "<option>支店情報取得中</option>";
    select.disabled = true;
    axios
        .get(`/branches/${selectElement.options[index].value}`)
        .then((res) => {
        const branches = res.data;
        select.innerHTML = "";
        select.disabled = false;
        branches.forEach((b) => {
            const option = document.createElement("option");
            option.value = b.id.toString();
            if (b.id === selectedBranchId) {
                option.selected = true;
            }
            option.textContent = b.name;
            select.appendChild(option);
        });
    })
        .catch((err) => {
        select.innerHTML =
            "<option>支店情報取得中にエラーが発生しました</option>";
        select.disabled = true;
        console.error(err);
    });
}
/**
 * Set the branch selector to the given branch.
 */
function setBranchSelector(branchId) {
    makeBranchSelector(document.getElementById("channel_id"), branchId);
}
/**
 * Set an initial channel selector.
 */
function setInitialChannel(selectElement, initial_id = 0) {
    selectElement.selectedIndex = initial_id;
    selectElement.dispatchEvent(new Event("change"));
}
/**
 * Set status by API
 */
function setStatus(type, id, selectElement) {
    const status = selectElement.selectedIndex;
    const url = `/${type}/set-status/${id}`;
    // Show the loading indicator while updating the status.
    selectElement.innerHTML = "<option>更新中...</option>";
    axios
        .post(url, { status })
        .then(() => {
        alert("ステータスを変更しました。");
        location.reload();
    })
        .catch((err) => {
        alert("ステータスの更新中にエラーが発生しました。");
        console.error(err);
        const options = selectElement.options;
        for (let i = 0; i < options.length; i++) {
            if (options[i].disabled) {
                options[i].selected = true;
                break;
            }
        }
    });
}
export { makeBranchSelector, setBranchSelector, setInitialChannel, setStatus };
