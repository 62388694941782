import React from "react";
import ConstructionCalendar from "../../template/components/ConstructionCalendar";
import { ScreenTitle } from "../../components/ScreenTitle";
const ScheduleIndex = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ScreenTitle, { title: "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB" }),
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-center" },
                        React.createElement("div", { className: "mb-3 w-100" },
                            React.createElement("div", { className: "mb-3" },
                                React.createElement("h2", { className: "fw-bold" }, "\u8ABF\u67FB\u65E5\u30FB\u5DE5\u4E8B\u65E5\u30AB\u30EC\u30F3\u30C0\u30FC"),
                                React.createElement("p", null,
                                    "\u73FE\u5834\u8ABF\u67FB\u65E5\u3001\u5DE5\u4E8B\u65E5\u3092",
                                    React.createElement("b", null, "\u78BA\u8A8D"),
                                    "\u3067\u304D\u307E\u3059\u3002"),
                                React.createElement("p", null,
                                    React.createElement("span", { className: "event-survey event-icon", style: { margin: "0 0.5rem 0 0" } }),
                                    React.createElement("b", null, "\u73FE\u5834\u8ABF\u67FB"),
                                    React.createElement("span", { className: "event-construction event-icon", style: { margin: "0 0.5rem 0 0.5rem" } }),
                                    React.createElement("b", null, "\u5DE5\u4E8B"))),
                            React.createElement(ConstructionCalendar, null))))))));
};
export { ScheduleIndex };
