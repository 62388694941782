import React, { useState } from 'react';
import { Case, Else, If, Switch, Then } from 'react-if';
import { http } from '../../lib/httpClient';
import { Link, useParams } from 'react-router-dom';
import { ProjectCard } from '../../components/pages/customers';
import { Layout } from '../../layouts/Layout';
import useSWR from 'swr';
import { AuthAtom } from '../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
import { Loading } from '../../components/Loading';
export const CustomerDetails = () => {
    const auth = useAtomValue(AuthAtom);
    const { id } = useParams();
    // const [customer, setCustomer] = useState<Customer>({} as Customer);
    const [status, setStatus] = useState(0);
    const { data: customer, isLoading, error, mutate, } = useSWR(`/customers/${id}`, (url) => http({ token: auth.token })
        .get(url)
        .then((res) => res.data.data));
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u9867\u5BA2\u60C5\u5831" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(If, { condition: isLoading },
                            React.createElement(Then, null,
                                React.createElement(Loading, null)),
                            React.createElement(Else, null,
                                React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-start" },
                                    React.createElement("section", { className: "w-100 mb-5" },
                                        React.createElement("h1", { className: "fw-bold" },
                                            React.createElement("ruby", null,
                                                customer?.name_kanji,
                                                React.createElement("rt", null, customer?.name_kana)),
                                            ' ',
                                            "\u69D8"),
                                        React.createElement("p", { className: "fw-bold m-0" }, "\u4F4F\u6240"),
                                        React.createElement("p", null,
                                            customer?.address_1,
                                            customer?.address_2,
                                            customer?.address_3,
                                            customer?.address_4),
                                        React.createElement("p", { className: "fw-bold m-0" }, "\u5099\u8003"),
                                        React.createElement("p", null, customer?.note),
                                        React.createElement("div", { className: "btn-group" },
                                            React.createElement(Link, { to: `/customers/${customer?.id}/edit`, className: "btn btn-system" },
                                                React.createElement("i", { className: "bi bi-pencil-square me-2" }),
                                                "\u7DE8\u96C6"),
                                            React.createElement("button", { type: "button", className: "btn btn-danger", "data-bs-toggle": "modal", "data-bs-target": "#deleteModal" },
                                                React.createElement("i", { className: "bi bi-trash-fill me-2" }),
                                                "\u524A\u9664"))),
                                    React.createElement("section", { className: "w-100 mb-5" },
                                        React.createElement("div", { className: "mb-3" },
                                            React.createElement("h2", { className: "fw-bold" }, "\u6848\u4EF6\u7BA1\u7406"),
                                            React.createElement(Link, { to: `/projects/create?customerId=${customer?.id}`, className: "btn btn-warning" }, "\u65B0\u898F\u6848\u4EF6\u767B\u9332")),
                                        React.createElement("ul", { className: "nav nav-pills nav-justified flex-column flex-md-row mb-3" },
                                            React.createElement("li", { className: "nav-item", role: "presentation" },
                                                React.createElement("button", { onClick: () => setStatus(0), className: `nav-link ${status === 0 ? 'active' : ''}` },
                                                    React.createElement("i", { className: "bi bi-exclamation-circle-fill me-2" }),
                                                    "\u672A\u51E6\u7406")),
                                            React.createElement("li", { className: "nav-item", role: "presentation" },
                                                React.createElement("button", { onClick: () => setStatus(1), className: `nav-link ${status === 1 ? 'active' : ''}` },
                                                    React.createElement("i", { className: "bi bi-check-circle-fill me-2" }),
                                                    "\u51E6\u7406\u6E08\u307F")),
                                            React.createElement("li", { className: "nav-item", role: "presentation" },
                                                React.createElement("button", { onClick: () => setStatus(2), className: `nav-link ${status === 2 ? 'active' : ''}` },
                                                    React.createElement("i", { className: "bi bi-x-circle-fill me-2" }),
                                                    "\u30AD\u30E3\u30F3\u30BB\u30EB"))),
                                        customer?.projects && (React.createElement("div", null,
                                            React.createElement(If, { condition: customer?.projects?.length < 1 },
                                                React.createElement(Then, null,
                                                    React.createElement("p", null,
                                                        React.createElement(Switch, null,
                                                            React.createElement(Case, { condition: status === 0 }, "\u672A\u51E6\u7406\u6848\u4EF6"),
                                                            React.createElement(Case, { condition: status === 1 }, "\u51E6\u7406\u6E08\u307F\u6848\u4EF6"),
                                                            React.createElement(Case, { condition: status === 2 }, "\u30AD\u30E3\u30F3\u30BB\u30EB\u3055\u308C\u305F\u6848\u4EF6")),
                                                        "\u306F\u3042\u308A\u307E\u305B\u3093\u3002")),
                                                React.createElement(Else, null,
                                                    React.createElement("p", null,
                                                        React.createElement("span", { className: "fw-bold" },
                                                            customer?.projects.filter((project) => project.status === status).length,
                                                            "\u4EF6"),
                                                        "\u306E",
                                                        React.createElement(Switch, null,
                                                            React.createElement(Case, { condition: status === 0 }, "\u672A\u5B8C\u4E86\u6848\u4EF6"),
                                                            React.createElement(Case, { condition: status === 1 }, "\u51E6\u7406\u6E08\u307F\u6848\u4EF6"),
                                                            React.createElement(Case, { condition: status === 2 }, "\u30AD\u30E3\u30F3\u30BB\u30EB\u3055\u308C\u305F\u6848\u4EF6")),
                                                        "\u304C\u3042\u308A\u307E\u3059"),
                                                    customer?.projects
                                                        ?.filter((project) => project.status === status)
                                                        ?.map((project) => (React.createElement(ProjectCard, { key: project.id, project: project, callback: () => mutate() })))))))))))))))));
};
