import React, { useEffect, useState } from 'react';
import { If, Then } from 'react-if';
import { http } from '../lib/httpClient';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { AuthAtom } from '../atoms/AuthAtom';
const Home = () => {
    const [information, setInformation] = useState([]);
    const [auth, setAuth] = useAtom(AuthAtom);
    const logout = () => {
        http({ token: auth.token })
            .post('/auth/logout')
            .then(() => {
            setAuth({
                token: '',
                user: {},
            });
        })
            .catch((err) => {
            alert(err);
        });
    };
    useEffect(() => {
        http({ token: auth.token })
            .get('/information')
            .then((res) => {
            setInformation(res.data);
        });
    }, []);
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "flex w-100 vh-100 flex-column justify-content-center align-items-center" },
                    React.createElement("img", { src: "/img/logo.png", className: "d-block mx-auto py-5", alt: "logo" }),
                    React.createElement(If, { condition: information.length > 0 },
                        React.createElement(Then, null,
                            React.createElement("div", { className: "bg-light border rounded p-3 mb-3" },
                                React.createElement("h2", { className: "h5 fw-bold" }, "\u304A\u77E5\u3089\u305B"),
                                React.createElement("ul", { className: "list-group" }, information.map((info) => (React.createElement("li", { key: info.id, className: "list-group-item" },
                                    React.createElement("p", { className: "mb-1 fw-bold" }, info.title),
                                    React.createElement("p", { className: "m-0" }, info.body)))))))),
                    React.createElement("div", { className: "d-flex flex-column gap-3 py-3" },
                        React.createElement(Link, { to: `/customers`, className: "btn btn-system" }, "\u9867\u5BA2\u691C\u7D22"),
                        React.createElement(Link, { to: `/projects`, className: "btn btn-system" }, "\u6848\u4EF6\u7BA1\u7406"),
                        React.createElement(Link, { to: `/estimates`, className: "btn btn-system" }, "\u898B\u7A4D\u66F8\u691C\u7D22"),
                        React.createElement(Link, { to: `/invoices`, className: "btn btn-system" }, "\u8ACB\u6C42\u66F8\u691C\u7D22"),
                        React.createElement(Link, { to: `/schedules`, className: "btn btn-system" }, "\u30B9\u30B1\u30B8\u30E5\u30FC\u30EB\u7BA1\u7406"),
                        React.createElement(Link, { to: `/sales`, className: "btn btn-system" }, "\u58F2\u4E0A\u7BA1\u7406"),
                        React.createElement(Link, { to: `/masters`, className: "btn btn-dark" }, "\u30DE\u30B9\u30BF\u30E1\u30F3\u30C6\u30CA\u30F3\u30B9"),
                        React.createElement(Link, { to: `/feedbacks`, className: "btn btn-dark" }, "\u30D5\u30A3\u30FC\u30C9\u30D0\u30C3\u30AF"),
                        React.createElement("a", { onClick: () => {
                                const address = prompt('メールアドレスを入力してください');
                                if (address) {
                                    location.href = `/email/test/?address=${address}`;
                                }
                            }, className: "btn btn-dark" }, "\u30E1\u30FC\u30EB\u9001\u4FE1\u30C6\u30B9\u30C8"),
                        React.createElement("button", { className: "btn btn-danger", onClick: logout }, "\u30ED\u30B0\u30A2\u30A6\u30C8")))))));
};
export { Home };
