import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { Layout } from '../../../layouts/Layout';
import { useAtomValue } from 'jotai';
import { AuthAtom } from '../../../atoms/AuthAtom';
const MasterUserForm = () => {
    const navigate = useNavigate();
    const auth = useAtomValue(AuthAtom);
    const { id } = useParams();
    const { register, handleSubmit, reset } = useForm();
    const [roles, setRoles] = useState([]);
    const onSubmit = (data) => {
        if (id) {
            http({ token: auth.token })
                .put(`/users/${id}`, data)
                .then((res) => {
                if (res.data) {
                    navigate('/masters/users');
                }
                else {
                    alert('更新に失敗しました。');
                }
            });
        }
        else {
            http({ token: auth.token })
                .post('/users', data)
                .then((res) => {
                if (res.data) {
                    navigate('/masters/users');
                }
                else {
                    alert('登録に失敗しました。');
                }
            });
        }
    };
    useEffect(() => {
        http({ token: auth.token })
            .get('/roles')
            .then((res) => {
            setRoles(res.data.data);
            if (id) {
                http({ token: auth.token })
                    .get(`/users/${id}`)
                    .then((res) => res.data)
                    .then((data) => {
                    reset(data.data);
                });
            }
        });
    }, [id]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: `ユーザー${id ? '情報更新' : '登録'}` },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "name" }, "\u6C0F\u540D"),
                                React.createElement("input", { type: "text", className: "form-control", id: "name", placeholder: "\u6C0F\u540D", ...register('name') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "username" }, "\u30E6\u30FC\u30B6\u30FC\u540D"),
                                React.createElement("input", { type: "text", className: "form-control", id: "username", placeholder: "\u30E6\u30FC\u30B6\u30FC\u540D", ...register('username') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "tel" }, "\u96FB\u8A71\u756A\u53F7"),
                                React.createElement("input", { type: "tel", className: "form-control", id: "tel", placeholder: "\u96FB\u8A71\u756A\u53F7", ...register('tel') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "email" }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
                                React.createElement("input", { type: "email", className: "form-control", id: "email", placeholder: "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9", ...register('email') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "password" }, "\u30D1\u30B9\u30EF\u30FC\u30C9"),
                                React.createElement("input", { type: "password", className: "form-control", id: "password", placeholder: "\u30D1\u30B9\u30EF\u30FC\u30C9", ...register('password') })),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("label", { htmlFor: "role_id" }, "\u5F79\u8077"),
                                React.createElement("select", { className: "form-select", id: "role_id", ...register('role_id') },
                                    React.createElement("option", { disabled: true }, "\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"),
                                    roles.map((role) => (React.createElement("option", { key: role.id, value: role.id }, role.display_name))))),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("input", { type: "checkbox", className: "form-check-input", id: "is_admin", ...register('is_admin') }),
                                React.createElement("label", { htmlFor: "is_admin" }, "\u7BA1\u7406\u8005")),
                            React.createElement("div", { className: "form-group mb-3" },
                                React.createElement("input", { type: "checkbox", className: "form-check-input", id: "is_deleted", ...register('is_deleted') }),
                                React.createElement("label", { htmlFor: "is_deleted" }, "\u524A\u9664\u6E08\u307F")),
                            React.createElement("div", { className: "d-flex gap-1" },
                                React.createElement("button", { type: "submit", className: "btn btn-system" },
                                    id ? '更新' : '登録',
                                    "\u3059\u308B")))))))));
};
export { MasterUserForm };
