import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { http } from '../../../lib/httpClient';
import { Case, Switch } from 'react-if';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
import { Layout } from '../../../layouts/Layout';
const MasterBankIndex = () => {
    const auth = useAtomValue(AuthAtom);
    const [banks, setBanks] = useState([]);
    useEffect(() => {
        http({ token: auth.token })
            .get('/banks')
            .then((data) => {
            setBanks(data.data.data);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u9280\u884C\u53E3\u5EA7\u7BA1\u7406" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement(Link, { to: "/masters/banks/create", className: "btn btn-system" }, "\u53E3\u5EA7\u8FFD\u52A0"))),
                React.createElement("div", { className: "row mb-3" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "table-responsive" },
                            React.createElement("table", { className: "table align-middle" },
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "ID"),
                                        React.createElement("th", null, "\u540D\u524D"),
                                        React.createElement("th", null, "\u652F\u5E97"),
                                        React.createElement("th", null, "\u7A2E\u5225"),
                                        React.createElement("th", null, "\u53E3\u5EA7\u756A\u53F7"),
                                        React.createElement("th", null, "\u6709\u52B9"),
                                        React.createElement("th", null, "\u64CD\u4F5C"))),
                                React.createElement("tbody", null, banks.map((bank) => (React.createElement("tr", { key: bank.id },
                                    React.createElement("td", null, bank.id),
                                    React.createElement("td", null,
                                        React.createElement("strong", null, bank.name)),
                                    React.createElement("td", null, bank.branch_name),
                                    React.createElement("td", null,
                                        React.createElement(Switch, null,
                                            React.createElement(Case, { condition: bank.account_type === 1 }, "\u666E\u901A"),
                                            React.createElement(Case, { condition: bank.account_type === 2 }, "\u5F53\u5EA7"),
                                            React.createElement(Case, { condition: bank.account_type === 9 }, "\u305D\u306E\u4ED6"))),
                                    React.createElement("td", null, bank.account_number),
                                    React.createElement("td", null, bank.is_enabled === 1 ? 'はい' : 'いいえ'),
                                    React.createElement("td", { className: "d-flex flex-row gap-1" },
                                        React.createElement(Link, { to: `/masters/banks/${bank.id}/edit`, className: "btn btn-system" }, "\u7DE8\u96C6"),
                                        React.createElement("button", { type: "button", className: "btn btn-danger", onClick: () => {
                                                if (window.confirm('本当に削除してもよろしいですか？')) {
                                                    http({ token: auth.token })
                                                        .delete(`/banks/${bank.id}`)
                                                        .then((res) => {
                                                        if (res.data) {
                                                            window.location.reload();
                                                        }
                                                        else {
                                                            alert('削除に失敗しました。');
                                                        }
                                                    });
                                                }
                                            } }, "\u524A\u9664"))))))))))))));
};
export { MasterBankIndex };
