import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from '../../layouts/Layout';
const MasterIndex = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u30DE\u30B9\u30BF\u7BA1\u7406\uFF08\u7BA1\u7406\u8005\uFF09" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-12" },
                        React.createElement("div", { className: "row mb-3" },
                            React.createElement("div", { className: "col-md-3" },
                                React.createElement(Link, { to: "/masters/company", className: "btn btn-system d-block py-3 my-2" }, "\u81EA\u793E\u60C5\u5831")),
                            React.createElement("div", { className: "col-md-3" },
                                React.createElement(Link, { to: "/masters/banks", className: "btn btn-system d-block py-3 my-2" }, "\u9280\u884C\u53E3\u5EA7\u7BA1\u7406")),
                            React.createElement("div", { className: "col-md-3" },
                                React.createElement(Link, { to: "/masters/users", className: "btn btn-system d-block py-3 my-2" }, "\u30E6\u30FC\u30B6\u30FC")),
                            React.createElement("div", { className: "col-md-3" },
                                React.createElement(Link, { to: "/masters/roles", className: "btn btn-system d-block py-3 my-2" }, "\u5F79\u5272\u30FB\u6A29\u9650")),
                            React.createElement("div", { className: "col-md-3" },
                                React.createElement(Link, { to: "/masters/items", className: "btn btn-system d-block py-3 my-2" }, "\u5546\u54C1")),
                            React.createElement("div", { className: "col-md-3" },
                                React.createElement(Link, { to: "/masters/channels", className: "btn btn-system d-block py-3 my-2" }, "\u8CA9\u58F2\u30C1\u30E3\u30CD\u30EB")),
                            React.createElement("div", { className: "col-md-3" },
                                React.createElement(Link, { to: "/masters/branches", className: "btn btn-system d-block py-3 my-2" }, "\u652F\u5E97"))),
                        React.createElement("div", { className: "row mb-5" },
                            React.createElement("div", { className: "col-12" },
                                React.createElement("h2", { className: "h3 fw-bold" }, "\u30D0\u30C3\u30AF\u30A2\u30C3\u30D7")),
                            React.createElement("div", { className: "col-md-6" },
                                React.createElement(Link, { to: "/master/export/sql", className: "btn btn-system" }, "Export as SQL WIP")))))))));
};
export { MasterIndex };
