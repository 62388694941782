import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider as JotaiProvider, useAtomValue } from 'jotai';
import 'react-toastify/dist/ReactToastify.css';
// Home
import { Home } from './home';
// Masters
import { MasterIndex } from './masters';
import { MasterUserIndex } from './masters/users';
import { MasterUserForm } from './masters/users/form';
import { MasterItemIndex } from './masters/items';
import { MasterItemForm } from './masters/items/form';
import { MasterChannelIndex } from './masters/channels';
import { MasterChannelForm } from './masters/channels/form';
import { MasterBranchIndex } from './masters/branches';
import { MasterBranchForm } from './masters/branches/form';
import { MasterCompanyIndex } from './masters/company';
import { MasterBankIndex } from './masters/banks';
import { MasterBankForm } from './masters/banks/form';
// Feedbacks
import { FeedbackIndex } from './feedbacks';
// Sales
import { SalesIndex } from './sales';
import { SalesSummary1 } from './sales/summary_1';
// 404
import { NotFoundPage } from './404';
// Schedules
import { ScheduleIndex } from './schedules';
// Login
import { LoginPage } from './login';
// Projects
import { ProjectIndex } from './projects';
import { ProjectForm } from './projects/form';
import { ProjectDetails } from './projects/details';
// Estimates
import { EstimateIndex } from './estimates';
// Customers
import { CustomerSearch } from './customers/search';
import { CustomerDetails } from './customers/details';
import { CustomerForm } from './customers/form';
// Claims
import { ClaimDetails } from './claims/details';
import { ClaimForm } from './claims/form';
// Masters
import { MasterRoleIndex } from './masters/roles';
import { MasterRoleForm } from './masters/roles/form';
// Others
import { SWRConfig } from 'swr';
import { AuthAtom } from '../atoms/AuthAtom';
import { ToastContainer } from 'react-toastify';
const Router = () => {
    const auth = useAtomValue(AuthAtom);
    return (React.createElement(BrowserRouter, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/login", element: React.createElement(LoginPage, null) }),
            (auth.token && (React.createElement(React.Fragment, null,
                React.createElement(Route, { path: "/", element: React.createElement(Home, null) }),
                React.createElement(Route, { path: "/customers", element: React.createElement(CustomerSearch, null) }),
                React.createElement(Route, { path: "/customers/create", element: React.createElement(CustomerForm, null) }),
                React.createElement(Route, { path: "/customers/:id", element: React.createElement(CustomerDetails, null) }),
                React.createElement(Route, { path: "/customers/:id/edit", element: React.createElement(CustomerForm, null) }),
                React.createElement(Route, { path: "/projects", element: React.createElement(ProjectIndex, null) }),
                React.createElement(Route, { path: "/projects/create", element: React.createElement(ProjectForm, null) }),
                React.createElement(Route, { path: "/projects/:id", element: React.createElement(ProjectDetails, null) }),
                React.createElement(Route, { path: "/projects/:id/edit", element: React.createElement(ProjectForm, null) }),
                React.createElement(Route, { path: "/estimates", element: React.createElement(EstimateIndex, null) }),
                React.createElement(Route, { path: "/claims/create", element: React.createElement(ClaimForm, null) }),
                React.createElement(Route, { path: "/claims", element: React.createElement(ClaimDetails, null) }),
                React.createElement(Route, { path: "/claims/:id/edit", element: React.createElement(ClaimForm, null) }),
                React.createElement(Route, { path: "/schedules", element: React.createElement(ScheduleIndex, null) }),
                React.createElement(Route, { path: "/sales", element: React.createElement(SalesIndex, null) }),
                React.createElement(Route, { path: "/sales/Summary1", element: React.createElement(SalesSummary1, null) }),
                React.createElement(Route, { path: "/masters", element: React.createElement(MasterIndex, null) }),
                React.createElement(Route, { path: "/masters/company", element: React.createElement(MasterCompanyIndex, null) }),
                React.createElement(Route, { path: "/masters/banks", element: React.createElement(MasterBankIndex, null) }),
                React.createElement(Route, { path: "/masters/banks/create", element: React.createElement(MasterBankForm, null) }),
                React.createElement(Route, { path: "/masters/banks/:id/edit", element: React.createElement(MasterBankForm, null) }),
                React.createElement(Route, { path: "/masters/users", element: React.createElement(MasterUserIndex, null) }),
                React.createElement(Route, { path: "/masters/users/create", element: React.createElement(MasterUserForm, null) }),
                React.createElement(Route, { path: "/masters/users/:id/edit", element: React.createElement(MasterUserForm, null) }),
                React.createElement(Route, { path: "/masters/roles", element: React.createElement(MasterRoleIndex, null) }),
                React.createElement(Route, { path: "/masters/roles/create", element: React.createElement(MasterRoleForm, null) }),
                React.createElement(Route, { path: "/masters/roles/:id/edit", element: React.createElement(MasterRoleForm, null) }),
                React.createElement(Route, { path: "/masters/items", element: React.createElement(MasterItemIndex, null) }),
                React.createElement(Route, { path: "/masters/items/create", element: React.createElement(MasterItemForm, null) }),
                React.createElement(Route, { path: "/masters/items/:id/edit", element: React.createElement(MasterItemForm, null) }),
                React.createElement(Route, { path: "/masters/channels", element: React.createElement(MasterChannelIndex, null) }),
                React.createElement(Route, { path: "/masters/channels/create", element: React.createElement(MasterChannelForm, null) }),
                React.createElement(Route, { path: "/masters/channels/:id/edit", element: React.createElement(MasterChannelForm, null) }),
                React.createElement(Route, { path: "/masters/branches", element: React.createElement(MasterBranchIndex, null) }),
                React.createElement(Route, { path: "/masters/branches/create", element: React.createElement(MasterBranchForm, null) }),
                React.createElement(Route, { path: "/masters/branches/:id/edit", element: React.createElement(MasterBranchForm, null) }),
                React.createElement(Route, { path: "/feedbacks", element: React.createElement(FeedbackIndex, null) })))) || React.createElement(Route, { path: "*", element: React.createElement(LoginPage, null) }),
            React.createElement(Route, { path: "*", element: React.createElement(NotFoundPage, null) }))));
};
const App = () => {
    return (React.createElement("div", null,
        React.createElement(JotaiProvider, null,
            React.createElement(SWRConfig, { value: { revalidateOnFocus: false } },
                React.createElement(Router, null))),
        React.createElement(ToastContainer, { position: "top-center", autoClose: 5000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true, theme: "colored" })));
};
const container = document.getElementById('app');
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(App, null));
}
