import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { ScreenTitle } from '../components/ScreenTitle';
const IS_DEV = true;
const Layout = ({ children, title, }) => {
    const location = useLocation();
    return (React.createElement("div", null,
        React.createElement(AnimatePresence, { mode: "wait", key: location.pathname },
            React.createElement(motion.div, { key: location.pathname, initial: IS_DEV ? {} : { x: 300, opacity: 0 }, animate: IS_DEV ? {} : { x: 0, opacity: 1 }, exit: IS_DEV ? {} : { x: -300, opacity: 0 }, transition: IS_DEV ? {} : { duration: 0.3 } },
                React.createElement(ScreenTitle, { title: title || '' }),
                React.createElement("div", { className: "py-3" }, children),
                React.createElement("div", null)))));
};
export { Layout };
