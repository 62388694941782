import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { http } from '../../../lib/httpClient';
import { AuthAtom } from '../../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
import { Layout } from '../../../layouts/Layout';
const MasterCompanyIndex = () => {
    const auth = useAtomValue(AuthAtom);
    const navigate = useNavigate();
    const { register, handleSubmit, reset } = useForm();
    const onSubmit = (data) => {
        http({ token: auth.token })
            .put('/company', data)
            .then((res) => {
            if (res.data) {
                navigate('/masters');
            }
            else {
                alert('登録に失敗しました。');
            }
        });
    };
    useEffect(() => {
        http({ token: auth.token })
            .get(`/company`)
            .then((res) => {
            reset(res.data.data);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { title: "\u81EA\u793E\u60C5\u5831\u66F4\u65B0" },
            React.createElement("div", { className: "container" },
                React.createElement("form", { onSubmit: handleSubmit(onSubmit) },
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "name" }, "\u81EA\u793E\u540D"),
                        React.createElement("input", { type: "text", className: "form-control", id: "name", placeholder: "\u81EA\u793E\u540D", ...register('name') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "name_kana" }, "\u81EA\u793E\u540D\uFF08\u30AB\u30CA\uFF09"),
                        React.createElement("input", { type: "text", className: "form-control", id: "name_kana", placeholder: "\u81EA\u793E\u540D\uFF08\u30AB\u30CA\uFF09", ...register('name_kana') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "representative_name" }, "\u4EE3\u8868\u8005\u540D"),
                        React.createElement("input", { type: "text", className: "form-control", id: "representative_name", placeholder: "\u4EE3\u8868\u8005\u540D", ...register('representative_name') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "postal_code" }, "\u90F5\u4FBF\u756A\u53F7"),
                        React.createElement("input", { type: "text", className: "form-control", id: "postal_code", placeholder: "\u90F5\u4FBF\u756A\u53F7", ...register('postal_code') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "tel" }, "\u96FB\u8A71\u756A\u53F7"),
                        React.createElement("input", { type: "text", className: "form-control", id: "tel", placeholder: "\u96FB\u8A71\u756A\u53F7", ...register('tel') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "fax" }, "Fax"),
                        React.createElement("input", { type: "text", className: "form-control", id: "fax", placeholder: "Fax", ...register('fax') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "address_1" }, "\u4F4F\u6240"),
                        React.createElement("input", { type: "text", className: "form-control", id: "address_1", placeholder: "\u4F4F\u62401", ...register('address_1') }),
                        React.createElement("input", { type: "text", className: "form-control", id: "address_2", placeholder: "\u4F4F\u62402", ...register('address_2') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "corporate_number" }, "\u6CD5\u4EBA\u756A\u53F7"),
                        React.createElement("input", { type: "text", className: "form-control", id: "corporate_number", placeholder: "\u6CD5\u4EBA\u756A\u53F7", ...register('corporate_number') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "invoice_registration_number" }, "\u9069\u683C\u8ACB\u6C42\u66F8\u767A\u884C\u4E8B\u696D\u8005\u756A\u53F7"),
                        React.createElement("input", { type: "text", className: "form-control", id: "invoice_registration_number", placeholder: "\u9069\u683C\u8ACB\u6C42\u66F8\u767A\u884C\u4E8B\u696D\u8005\u756A\u53F7", ...register('invoice_registration_number') })),
                    React.createElement("div", { className: "form-group mb-3" },
                        React.createElement("label", { htmlFor: "year_start_month" }, "\u5E74\u5EA6\u958B\u59CB\u6708"),
                        React.createElement("input", { type: "number", min: 1, max: 12, className: "form-control", id: "year_start_month", placeholder: "\u5E74\u5EA6\u958B\u59CB\u6708", ...register('year_start_month') })),
                    React.createElement("div", { className: "d-flex gap-1" },
                        React.createElement("button", { type: "submit", className: "btn btn-system" }, "\u66F4\u65B0\u3059\u308B")))))));
};
export { MasterCompanyIndex };
