import React, { useEffect, useState } from 'react';
import { http } from '../../lib/httpClient';
import { Link } from 'react-router-dom';
import { Else, If, Then } from 'react-if';
import { AuthAtom } from '../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
const ClaimDetails = () => {
    const auth = useAtomValue(AuthAtom);
    const [claim, setClaim] = useState({});
    useEffect(() => {
        http({ token: auth.token })
            .get('/claims')
            .then((res) => {
            setClaim(res.data.data);
        });
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-12" },
                    React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-start" },
                        React.createElement("section", { className: "w-100 mb-5" },
                            React.createElement("p", { className: "fw-bold text-muted mb-1" },
                                claim?.project?.customer.name_kanji,
                                " \u69D8"),
                            React.createElement("h1", { className: "fw-bold" }, claim.name),
                            React.createElement("p", { className: "fw-bold text-muted" },
                                "\u30AF\u30EC\u30FC\u30E0ID: ",
                                claim.id),
                            React.createElement(Link, { to: `/claims/${claim.id}/edit`, className: "btn btn-system" },
                                React.createElement("i", { className: "bi bi-pencil-square me-2" }),
                                "\u7DE8\u96C6"),
                            React.createElement("button", { type: "button", className: "btn btn-danger", "data-bs-toggle": "modal", "data-bs-target": "#deleteModal" },
                                React.createElement("i", { className: "bi bi-trash-fill me-2" }),
                                "\u524A\u9664")),
                        React.createElement("section", { className: "w-100 mb-5" },
                            React.createElement("h2", { className: "fw-bold mb-3" }, "\u30AF\u30EC\u30FC\u30E0\u5185\u5BB9"),
                            React.createElement("p", { className: "" },
                                React.createElement(If, { condition: claim.description },
                                    React.createElement(Then, null, claim.description),
                                    React.createElement(Else, null, "\u30AF\u30EC\u30FC\u30E0\u5185\u5BB9\u304C\u767B\u9332\u3055\u308C\u3066\u3044\u307E\u305B\u3093\u3002"))))))))));
};
export { ClaimDetails };
