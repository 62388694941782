import { format } from 'date-fns';
/**
 *
 * ex) 20230727
 * @param d
 * @returns
 */
export const toDateString = (d) => format(new Date(d), 'yyyyMMdd');
/**
 *
 * ex) 2023/07/27
 * @param d
 * @returns
 */
export const toSeparatedDateString = (d) => format(new Date(d), 'yyyy/MM/dd');
/**
 *
 * ex) 2023/07/27 4:30:00 PM
 * @param d
 * @returns
 */
export const toDateTimeString = (d) => format(new Date(d), 'yyyy/LL/dd pp');
/**
 *
 * ex) XXX-XXXX
 * @param p
 * @returns
 */
export const toPostalCodeString = (p) => {
    const postalCodeArray = p.split('');
    postalCodeArray.splice(3, 0, '-');
    return postalCodeArray.join('');
};
/**
 *
 * ex) ¥1,000
 * @param p
 * @returns
 */
export const toMoneyString = (price) => price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' });
