import React from 'react';
import { http } from '../../lib/httpClient';
import { Link } from 'react-router-dom';
import { toSeparatedDateString } from '../../lib/format';
import { AuthAtom } from '../../atoms/AuthAtom';
import { useAtomValue } from 'jotai';
const ClaimCard = ({ claim }) => {
    const auth = useAtomValue(AuthAtom);
    const updateStatus = (nextStatus) => {
        http({ token: auth.token })
            .put(`/claims/${claim.id}`, {
            status: Number(nextStatus),
        })
            .then(() => {
            alert('更新しました。');
            window.location.reload();
        })
            .catch((err) => {
            alert(err);
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "card mb-3" },
            React.createElement("div", { className: "card-body" },
                React.createElement("h5", { className: "card-title fw-bold" }, claim.name),
                React.createElement("div", { className: "card-text" },
                    React.createElement("p", { className: "mb-2" },
                        "\u65BD\u5DE5\u65E5: ",
                        toSeparatedDateString(claim.construction_date),
                        React.createElement("br", null),
                        "\u53D7\u4ED8\u65E5: ",
                        toSeparatedDateString(claim.construction_date))),
                React.createElement("div", { className: "text-end" },
                    React.createElement("div", { className: "d-inline-block" },
                        React.createElement("select", { className: "form-select", "aria-label": "Status selector", onChange: (e) => updateStatus(e.target.value) },
                            React.createElement("option", { selected: claim.status === 0, value: "0" }, "\u672A\u51E6\u7406"),
                            React.createElement("option", { selected: claim.status === 1, value: "1" }, "\u51E6\u7406\u6E08\u307F"),
                            React.createElement("option", { selected: claim.status === 2, value: "2" }, "\u30AD\u30E3\u30F3\u30BB\u30EB"))),
                    React.createElement(Link, { to: `/claims/${claim.id}`, className: "btn btn-system px-4" }, "\u8A73\u7D30"))))));
};
/* {-- 削除モーダル --} */
const DeletionModal = () => {
    return (React.createElement("div", { className: "modal fade", id: "deleteModal", tabIndex: -1, "aria-labelledby": "deleteModalLabel", "aria-hidden": "true" },
        React.createElement("div", { className: "modal-dialog modal-dialog-centered", role: "document" },
            React.createElement("div", { className: "modal-content" },
                React.createElement("div", { className: "modal-header" },
                    React.createElement("h5", { className: "modal-title", id: "deleteModalLabel" }, "\u524A\u9664\u78BA\u8A8D"),
                    React.createElement("button", { type: "button", className: "btn-close", "data-bs-dismiss": "modal", "aria-label": "Close" })),
                React.createElement("div", { className: "modal-body" },
                    React.createElement("p", { className: "m-0" },
                        "\u3053\u306E\u6848\u4EF6\u3068\u305D\u308C\u306B\u7D10\u3065\u304F\u30AF\u30EC\u30FC\u30E0\u3092\u524A\u9664\u3057\u307E\u3059\u3002",
                        React.createElement("br", null),
                        "\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F",
                        React.createElement("br", null),
                        React.createElement("strong", null, "\u203B\u3053\u306E\u64CD\u4F5C\u306F\u53D6\u308A\u6D88\u305B\u307E\u305B\u3093\u3002"))),
                React.createElement("div", { className: "modal-footer" },
                    React.createElement("button", { type: "button", className: "btn btn-secondary", "data-bs-dismiss": "modal" }, "\u30AD\u30E3\u30F3\u30BB\u30EB"),
                    React.createElement("form", { action: "{ route('projects.delete', ['id' => project?.id]) }", method: "post", className: "d-inline-block" },
                        React.createElement("button", { type: "submit", className: "btn btn-danger" },
                            React.createElement("i", { className: "bi bi-trash-fill me-2" }),
                            "\u524A\u9664")))))));
};
export { ClaimCard, DeletionModal };
