import { useAtom } from 'jotai';
import { AuthAtom } from '../atoms/AuthAtom';
import { http } from '../lib/httpClient';
export const useToken = () => {
    const [auth, setAuth] = useAtom(AuthAtom);
    const refreshToken = async () => {
        http()
            .get('/auth/refresh')
            .then((res) => {
            setAuth({ ...auth, token: res.data.token });
        })
            .catch(() => {
            setAuth({ ...auth, token: '' });
        });
    };
    return {
        token: auth.token,
        setToken: (token) => setAuth({ ...auth, token }),
        refreshToken,
    };
};
