"use strict";
window._ = require("lodash");
require("@popperjs/core");
require("bootstrap");
window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] =
    "XMLHttpRequest";
window.addEventListener("load", () => {
    console.log("Trying to register service worker...");
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker
            .register("/sw.js")
            .then(() => {
            console.log("ServiceWorker registered");
        })
            .catch((error) => {
            console.warn("ServiceWorker error", error);
        });
    }
});
window.addEventListener("load", () => {
    // Loading event
    if (document.getElementById("loading")) {
        setTimeout(() => {
            document.getElementById("loading").style.display =
                "none";
        }, 100);
    }
    if (document.getElementById("flash")) {
        setTimeout(() => {
            document.getElementById("flash").animate([
                { opacity: 1, visibility: "visible" },
                { opacity: 0, visibility: "hidden" },
            ], {
                duration: 300,
                easing: "ease-in-out",
                fill: "forwards",
            });
        }, 3000);
    }
});
