import React from "react";
import { Link } from "react-router-dom";
const NotFoundPage = () => {
    return (React.createElement("div", { className: "container" },
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-12" },
                React.createElement("div", { className: "flex w-100 vh-100 flex-column justify-content-center align-items-center" },
                    React.createElement("img", { src: "/img/logo.png", className: "d-block mx-auto my-3", alt: "logo" }),
                    React.createElement("div", { className: "d-flex flex-column justify-content-center align-items-center" },
                        React.createElement("h1", null, "404 Not Found"),
                        React.createElement(Link, { to: "/", className: "btn btn-system" }, "\u30DB\u30FC\u30E0\u3078")))))));
};
export { NotFoundPage };
